import React, { Component } from 'react';
import axios from 'axios'
import { validEmailRegex, strongPasswordRegex, usernameRegex, mobilenumberRegex, validateForm } from '../../RegxConst'
import authservice from '../service/authservice';
import { TermsAndConditions } from './TermsAndConditions';
import { container, notifyUser, type } from '../../utility/notifyUser';
import Loader from '../../utility/Loader';
import styles from '../authentication.module.css';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import SetAdvertiseLocation from '../../SetAdvertiseLocation';
import priviliege from '../../../assets/priviliege.gif'
import goodbye from '../../../assets/goodbye.gif'
import discount from '../../../assets/discount.gif'
import limited from '../../../assets/limited.gif'
import FooterStyle from '../../footer/FooterStyle.module.css'

class Registration extends Component {

    constructor(props) {
        super(props);
        this.addressOptions = [];
        this.selectedNeighbourhood = [];
        this.selectedLocality = [];
        this.selectedCity = [];
        this.selectedState = [];
        this.stateValidation = null;
        this.cityValidation = null;
        this.localityValidation = null;
        this.neighbourhoodValidation = null;
    }

    state = {
        firstName: '',
        isPopupOpen: false,
        lastName: '',
        username: '',
        email_address: '',
        password: '',
        confirmpassword: '',
        contact_number: '',
        tc: null,
        account_type: null,
        checked: false,
        age_checked: false,
        checkError: '',
        ageCheckError: '',
        radioError: '',
        referralKey: '',
        profilePhotoUrl: null,
        // profilePhoto: '',
        errors: {
            username: '',
            email_address: '',
            password: '',
            contact_number: '',
            refKey: '',
            confirmpassword: '',
        },
        termsModel: {
            open: false,
            model_type: '',
        },
        selectedOption: '',
        yearlyAccount: false,
        freeAccount: false,
        noTargetHalfYearlyAccount: false,
        halfYearlyPrepaidAccount: false,
        quarterlyPrepaidAccount: false,
        selectedNeighbourhood: [],
        selectedLocality: [],
        selectedCity: [],
        selectedState: [],
        fullAddress: [],
        prepaidStatus: '',
        prepaidPercentage: ''
    }

    componentDidMount() {
        this.prepaidOffer()
        Promise.all([
            axios.get(process.env.REACT_APP_BACKEND_URL + '/hpmgr/listing/getallstates'),
        ]).then(([getFullAddress]) => { // use arrow function to avoid loosing context, BTW you don't need to use axios.spread with ES2015 destructuring
            this.setState({ fullAddress: getFullAddress.data });
            this.props.fullAddress(getFullAddress.data)
            Loader.stopSpinner()
        }).catch(error => {
            Loader.stopSpinner()
            console.log(error, 'getting data failed');
        });

        window.scrollTo(0, 0); //while loading the component it will focus top
        if (authservice.getCurrentUser() !== null) {
            this.props.history.push('/')
        }
        const search = this.props.history.location.search
        const refKey = new URLSearchParams(search).get("ref")
        if (refKey) {
            this.handleRefKey(refKey)
        }
    }

    _handleState = (selectedStates) => {
        if (selectedStates.length)
            this.selectedState = selectedStates;
        else
            this.selectedState = [];
    }

    _handleCity = (e) => {
        if (e.length)
            this.selectedCity = e;
        else
            this.selectedCity = [];
    }
    _handleLocality = (e) => {
        if (e.length)
            this.selectedLocality = e;
        else
            this.selectedLocality = [];
    }
    _handleNeighbourhood = (e) => {
        if (e.length)
            this.selectedNeighbourhood = e
        else
            this.selectedNeighbourhood = [];
    }

    setAddressOptions = (options) => {
        this.addressOptions = options
    }

    setStateValidationMethod = (m) => {
        this.stateValidation = m;
    }

    setCityValidationMethod = (m) => {
        this.cityValidation = m;
    }

    setLocalityValidationMethod = (m) => {
        this.localityValidation = m;
    }

    setNeighbourhoodValidationMethod = (m) => {
        this.neighbourhoodValidation = m;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let states = ''
        this.selectedState.map(data => {
            console.log(data)
            states = data.value
        })
        localStorage.setItem('state', states)
        let cities = ''
        this.selectedCity.map(data => {
            console.log(data)
            cities = data.value
        })
        localStorage.setItem('city', cities)
        let localities = ''
        this.selectedLocality.map(data => {
            console.log(data)
            localities = data.value
        })
        localStorage.setItem('locality', localities)
        let neighbourhoods = ''
        this.selectedNeighbourhood.map(data => {
            console.log(data)
            neighbourhoods = data.value
        })
        localStorage.setItem('neighbourhood', neighbourhoods)
        if (this.state.selectedOption === '') {
            this.setState({ radioError: "Please Select one Account type Option." })
        } else if (this.state.checked === false) {
            this.setState({ checkError: "Please Check The Check Box to Agree Upon The Terms & Conditions and Privacy Policy." })
        } else if (this.state.age_checked === false) {
            this.setState({ ageCheckError: "Please Check The Check Box to Agree Upon The Age above 18." })
        }
        else if (validateForm(this.state.errors)) {
            const data = {
                userName: this.state.username,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                password: this.state.password,
                mobileNumber: this.state.contact_number,
                email: this.state.email_address,
                referralKey: this.state.referralKey,
                yearlyAccount: this.state.yearlyAccount,
                freeAccount: this.state.freeAccount,
                halfYearlyPrepaidAccount: this.state.halfYearlyPrepaidAccount,
                noTargetHalfYearlyAccount: this.state.noTargetHalfYearlyAccount,
                quarterlyPrepaidAccount: this.state.quarterlyPrepaidAccount,
                stateId: states,
                cityId: cities,
                localityId: localities,
                neighbourhoodId: neighbourhoods,
                // profilePhoto: this.state.profilePhoto
            }
            if (this.selectedState.length < 1) {
                this.setState({ open: true })
                notifyUser("Location", "Select Any State", type.DANGER)
            }
            else if (this.cityValidation) {
                if (this.selectedCity.length < 1) {
                    this.setState({ open: true })
                    notifyUser("Location", "Select Any City", type.DANGER)
                }
                else if (this.localityValidation) {
                    if (this.selectedLocality.length < 1) {
                        this.setState({ open: true })
                        notifyUser("Location", "Select Any Locality", type.DANGER)
                    }
                    else if (this.neighbourhoodValidation) {
                        if (this.selectedNeighbourhood.length < 1) {
                            this.setState({ open: true })
                            notifyUser("Location", "Select Any Neighbourhood", type.DANGER)
                        }
                        else {
                            this.setState({ open: false })
                            localStorage.getItem('neighbourhood', neighbourhoods)
                            axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                                Loader.stopSpinner();
                                if (response.data)
                                    this.props.history.push('/activation-success/' + this.props.match.params.verify)
                                // if (response.data)
                                //     this.props.history.push({
                                //         pathname: `/otp/${response.data.id}`,
                                //         search: `?auth_mod=${data.mobileNumber}`,
                                //         state: { email: data.email }
                                //     })
                            }).catch(error => {
                                if (error.response)
                                    notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                            })
                        }
                    }
                    else {
                        this.setState({ open: false })
                        localStorage.getItem('locality', localities)
                        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                            Loader.stopSpinner();
                            if (response.data)
                                this.props.history.push('/activation-success/' + this.props.match.params.verify)
                            // if (response.data)
                            //     this.props.history.push({
                            //         pathname: `/otp/${response.data.id}`,
                            //         search: `?auth_mod=${data.mobileNumber}`,
                            //         state: { email: data.email }
                            //     })
                        }).catch(error => {
                            if (error.response)
                                notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                        })
                    }
                }
                else {
                    this.setState({ open: false })
                    localStorage.getItem('city', cities)
                    axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                        Loader.stopSpinner();
                        if (response.data)
                            this.props.history.push('/activation-success/' + this.props.match.params.verify)
                        // if (response.data)
                        //     this.props.history.push({
                        //         pathname: `/otp/${response.data.id}`,
                        //         search: `?auth_mod=${data.mobileNumber}`,
                        //         state: { email: data.email }
                        //     })
                    }).catch(error => {
                        if (error.response)
                            notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                    })
                }
            }
            else {
                this.setState({ open: false })
                localStorage.getItem('state', states)
                axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                    Loader.stopSpinner();
                    if (response.data)
                        this.props.history.push('/activation-success/' + this.props.match.params.verify)
                    // if (response.data)
                    //     this.props.history.push({
                    //         pathname: `/otp/${response.data.id}`,
                    //         search: `?auth_mod=${data.mobileNumber}`,
                    //         state: { email: data.email }
                    //     })
                }).catch(error => {
                    if (error.response)
                        notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                })
            }
        }
        // }).catch(error => {
        //     console.log("Unable to upload your profile photo")
        //     notifyUser("Profile Picture", error.response.data, type.DANGER);
        //     Loader.stopSpinner()
        // })
    }

    refKeyAvailablityTimer = null
    handleRefKey = (e) => {
        const refkey = e.target ? e.target.value : e;
        this.setState({ referralKey: refkey })
        // if (this.refKeyAvailablityTimer) clearTimeout(this.refKeyAvailablityTimer)
        // let error = { ...this.state.errors }
        // if (refkey)
        //     this.refKeyAvailablityTimer = setTimeout(() => {
        //         axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/checkrefkey?refKey=" + refkey).then(
        //             response => {
        //                 error.refKey = response.data
        //                 this.setState({ errors: error })
        //             })
        //     }, 1000);
        // else {
        //     error.refKey = ''
        //     this.setState({ errors: error })
        // }
    }

    handleUserName = (e) => {
        e.preventDefault();
        if (this.userNameAvailabilityTimer) clearTimeout(this.userNameAvailabilityTimer)
        const userName = e.target.value;
        this.setState({ username: userName })
        let errors = { ...this.state.errors }
        this.userNameAvailabilityTimer = setTimeout(async () => {
            if (userName.length > 3 && userName.length < 16)
                if (usernameRegex.test(userName)) {
                    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/checkusername?userName=" + userName)
                    errors.username = response.data
                } else
                    errors.username = 'Username contains invalid characters';
            else
                errors.username = userName.length < 4 ? 'Username should be minimum of 4 characters' : 'Too long username';
            this.setState({ errors })
        }, 1000);
    }

    userNameAvailabilityTimer = null;
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        if (value) {
            switch (name) {
                case 'email_address': {
                    errors.email_address =
                        validEmailRegex.test(value)
                            ? ''
                            : 'Email is Not Valid!';
                    break;
                }
                case 'password': {
                    if (value.length < 8)
                        errors.password = 'Password should be minimum of 8 characters and must contain atleast 1 Lowercase , 1 Uppercase , 1 Numeric and 1 Special Character'
                    else
                        errors.password = strongPasswordRegex.test(value)
                            ? ""
                            : 'Password Must Contain at Least 1 Lowercase or 1 Uppercase , 1 Numeric and 1 Special Character';
                    errors.confirmpassword = this.state.confirmpassword && value !== this.state.confirmpassword ? 'Password Does Not Match' : ""
                    break;
                }
                case 'confirmpassword':
                    errors.confirmpassword = (value === this.state.password ? '' : 'Password Does Not Match');
                    break;
                case 'contact_number': {
                    errors.contact_number =
                        mobilenumberRegex.test(value)
                            ? (value.length < 10)
                                ? 'Mobile Number Must Have 10 Digits'
                                : ''
                            : 'Invalid Mobile Number (Hint: Do not enter STD/ISO code)'
                    break;
                }
                case 'tc': {
                    this.setState({ checked: !this.state.checked });
                    if (this.state.checked === false)
                        this.setState({ checkError: '' })
                    break;
                }
                case 'age_check': {
                    this.setState({ age_checked: !this.state.age_checked });
                    if (this.state.age_checked === false)
                        this.setState({ ageCheckError: '' })
                    break;
                }
                // case 'business': {
                //     this.setState({ age_checked: !this.state.age_checked });
                //     if (this.state.age_checked === false)
                //         this.setState({ ageCheckError: '' })
                //     break;
                // }
                // case 'content': {
                //     this.setState({ age_checked: !this.state.age_checked });
                //     if (this.state.age_checked === false)
                //         this.setState({ ageCheckError: '' })
                //     break;
                // }
                // case 'video': {
                //     this.setState({ age_checked: !this.state.age_checked });
                //     if (this.state.age_checked === false)
                //         this.setState({ ageCheckError: '' })
                //     break;
                // }
                // case 'photo': {
                //     this.setState({ age_checked: !this.state.age_checked });
                //     if (this.state.age_checked === false)
                //         this.setState({ ageCheckError: '' })
                //     break;
                // }
                // case 'influence': {
                //     this.setState({ age_checked: !this.state.age_checked });
                //     if (this.state.age_checked === false)
                //         this.setState({ ageCheckError: '' })
                //     break;
                // }
                default:
                    break;
            }
            this.setState({ errors, [name]: value })
        } else {
            errors[name] = '';
        }
    }

    openModal = (type) => {
        this.setState({
            termsModel: {
                open: true,
                model_type: type,
            }
        });
    }

    closeModal = () => {
        this.setState({
            termsModel: {
                open: false,
                model_type: '',
            }
        });
    }

    profilePopup = () => {
        this.setState((prevState) => ({
            isPopupOpen: !prevState.isPopupOpen,
        }));
    };

    handleProfilePhoto = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith("image")) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    profilePhotoUrl: e.target.result,
                    isPopupOpen: false  // Close the popup after successful image upload
                });
            };
            reader.readAsDataURL(file);

            this.selectedImage = file;
            this.profileDataChanged = true;
        }
    }

    prepaidOffer = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/orders/get-offer-status').then(response => {
            console.log(response.data)
            this.setState({ prepaidStatus: response.data.offerStatus });
            this.setState({ prepaidPercentage: response.data.percentage })
        }).catch(error => {
            console.error("Error fetching notifications:", error);
        })
    }

    handleOptionChange = (changeEvent) => {
        const value = changeEvent.target ? changeEvent.target.value : '';
        this.setState({ yearlyAccount: value === 'option1', halfYearlyPrepaidAccount: value === 'option3', quarterlyPrepaidAccount: value === 'option2', noTargetHalfYearlyAccount: value === 'option4', freeAccount: value === 'option5', selectedOption: value }, () => {
            this.setState({ radioError: this.state.selectedOption ? '' : 'Please Select one Account type Option.' })
        });
    }


    render() {

        const { errors } = this.state;

        return (
            <>
                <div className='billBoard'>
                    <div className='row col-md-12 m-0'>
                        <div className={`${styles.Registration} col-md-6`}>
                            <div key={'reg-form'}>
                                <TermsAndConditions closeModal={this.closeModal} terms={this.state.termsModel} />
                                <div className={`grid_container ${styles.classified_add}`} >
                                    <form onSubmit={this.handleSubmit}>
                                        <h2 className={`${styles.registration_header}`}>Registration Form</h2>
                                        {/* <label className={`${styles.reg_form_section_label}`} htmlFor="title"><strong>Subscription Models With Target </strong></label>
                                <div className="radio">
                                    <label className={`${styles.reg_form_section_label}`}>
                                        <input type="radio" name="account_type" value="option2" checked={this.state.selectedOption === 'option2'} onClick={() => this.openModal('quarter')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        &nbsp; Quarter Year** Subscription - Rs.1770/-*
                                    </label> <br></br>
                                </div>
                                <div className="radio">
                                    <label className={`${styles.reg_form_section_label}`}>
                                        <input type="radio" name="account_type" value="option3" checked={this.state.selectedOption === 'option3'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        &nbsp; Half Year** Subscription - Rs.3540/-*
                                    </label>
                                </div> */}
                                        <h2>About You</h2>
                                        <p>Please tick &#9989; all the applicable boxes.</p>
                                        <div className="row form-row col-md-12">
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="business" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I have business/businesses
                                            </label><br></br>
                                        </div>
                                        <div className="row form-row col-md-12">
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="content" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I can write content in English
                                            </label>
                                        </div>
                                        <div className="row form-row col-md-12">
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="video" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I can create video content
                                            </label>
                                        </div>
                                        <div className="row form-row col-md-12">
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="photo" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I can create photo content
                                            </label>
                                        </div>
                                        <div className="row form-row col-md-12">
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="influence" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I can influence people
                                            </label>
                                        </div><br></br>
                                        <h2>Choose Your Subscription</h2>
                                        <div className="row form-row col-md-12">
                                            <label className='container_radio'> Half Year** Subscription - Rs.4500/-*
                                                {/* Rs.3000/-* <img className='img-fluid' width='80px' src={limited} /> */}
                                                <input name="account_type" type="radio" value="option4" checked={this.state.selectedOption === 'option4'} onClick={() => this.openModal('paidHalf')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="row form-row col-md-12">
                                            <label className='container_radio'> Annual Subscription - Rs.9000/-*
                                                {/* Rs.6000/-* <strong>(Freedom Offer till 15 Aug)</strong> */}
                                                <input name="account_type" type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onClick={() => this.openModal('paidFull')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="row form-row col-md-12">
                                            <label className='container_radio'> Unpaid Annual Subscription - Rs.9000/-*
                                                <br></br> Accumulate the Subscription and Upgrade
                                                <input name="account_type" type="radio" value="option5" checked={this.state.selectedOption === 'option5'} onClick={() => this.openModal('free')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <label className={`${styles.reg_form_section_label}`}>&nbsp;&nbsp;*( + 18% GST)
                                            <br></br>&nbsp; **Renewal will be in Annual Subscription.
                                            {/* <br></br>&nbsp; Note: The withdrawal option will not be available during Quarter Year Subscription Period. */}
                                        </label>
                                        <div className={`${styles.error}`}>{this.state.radioError}</div>
                                        <div className={`${styles.reg_form_section} registration_padding`}>
                                            <div className="form-group row">
                                                <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">Name  </label></div>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" placeholder="First name"
                                                        name="firstName" required value={this.state.firstName} noValidate
                                                        onChange={(event) => this.setState({ firstName: event.target.value })}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control" placeholder="Surname"
                                                        name="lastName" required value={this.state.lastName} noValidate
                                                        onChange={(event) => this.setState({ lastName: event.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">User name  </label></div>
                                                <div className="col-md-8">
                                                    <input type="text" className="form-control" placeholder="User name"
                                                        name="username" required value={this.state.username} noValidate autoComplete="new-password"
                                                        onChange={this.handleUserName}
                                                    />
                                                    <span className={`${styles.error}`}>{errors.username}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Password</label></div>
                                                <div className="col-md-8">
                                                    <input type="password" className="form-control" placeholder="Password"
                                                        name="password" autoComplete="new-password" required
                                                        value={this.state.password} noValidate
                                                        onChange={(event) => { this.setState({ password: event.target.value }); this.handleChange(event) }}
                                                    />
                                                    <span className={`${styles.error}`}>{errors.password}</span>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label className={`${styles.reg_form_section_label}`} htmlFor="title">Confirm password  </label></div>
                                                <div className="col-md-8">
                                                    <input type="password" className="form-control" placeholder="Confirm password"
                                                        name="confirmpassword" required value={this.state.confirmpassword} noValidate
                                                        onChange={(event) => { this.setState({ confirmpassword: event.target.value }); this.handleChange(event) }}
                                                    />
                                                    <span className={`${styles.error}`}>{errors.confirmpassword}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Mobile number</label></div>
                                                <div className="col-md-8">
                                                    <input className="form-control" placeholder="Mobile number" noValidate maxLength="10"
                                                        name="contact_number" required value={this.state.contact_number}
                                                        onChange={(event) => { this.setState({ contact_number: event.target.value }); this.handleChange(event) }}
                                                    />
                                                    <span className={`${styles.error}`}>{errors.contact_number}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Email address</label></div>
                                                <div className="col-md-8">
                                                    <input className="form-control" placeholder="Email address" name="email_address"
                                                        required value={this.state.email_address} noValidate
                                                        onChange={(event) => { this.setState({ email_address: event.target.value }); this.handleChange(event) }}
                                                    />
                                                    <span className={`${styles.error}`}>{errors.email_address}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Invitation key</label></div>
                                                <div className="col-md-8">
                                                    {(window.location.href === window.location.origin + '/registration?ref=' + this.state.referralKey) ? <input className="form-control" placeholder="Enter invitation key"
                                                        name="referralKey" value={this.state.referralKey} noValidate
                                                        onChange={this.handleRefKey} readOnly
                                                    /> :
                                                        <input className="form-control" placeholder="Enter invitation key"
                                                            name="referralKey" value={this.state.referralKey} noValidate
                                                            onChange={this.handleRefKey}
                                                        />}
                                                    <span className={`${styles.error}`}>{errors.refKey}</span>
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <div className="col-md-4">
                                                    <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Location</label></div>
                                                <div className="col-md-8">
                                                    <SetAdvertiseLocation
                                                        //heading='Areas Served' isMulti
                                                        setFilterValidationMethod={this.setLocationValidationMethod}
                                                        allLocations={this.state.fullAddress}
                                                        selectedStates={this.state.selectedState}
                                                        selectedCities={this.state.selectedCity}
                                                        selectedLocalities={this.state.selectedLocality}
                                                        selectedNeighbourhoods={this.state.selectedNeighbourhood}
                                                        _handleState={this._handleState}
                                                        _handleCity={this._handleCity}
                                                        _handleLocality={this._handleLocality}
                                                        _handleNeighbourhood={this._handleNeighbourhood}
                                                        addressOptions
                                                        setAddressOptions={this.setAddressOptions}
                                                        labelClass={'col-md-8'}
                                                        setStateValidationMethod={this.setStateValidationMethod}
                                                        setCityValidationMethod={this.setCityValidationMethod}
                                                        setLocalityValidationMethod={this.setLocalityValidationMethod}
                                                        setNeighbourhoodValidationMethod={this.setNeighbourhoodValidationMethod}
                                                    />
                                                    <p className='location_p'>Tell us your location to see what is near you. (If your location is not available, please select your nearest location.)</p>
                                                </div>

                                            </div>
                                            <div className="row form-group">
                                                <div className={`${styles.form_check} col-md-12`}>
                                                    <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                        <input type="checkbox" className={`${styles.form_check_input}`} name="age_check" age_checked={this.state.age_checked}
                                                            onInput={this.handleChange} noValidate readOnly />I am above 18 Years of Age & Have a PAN.
                                                    </label>
                                                    <div className={`${styles.error}`}>{this.state.ageCheckError}</div>
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className={`${styles.form_check} col-md-12`}>
                                                    <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                        <input type="checkbox" className={`${styles.form_check_input}`} name="tc" checked={this.state.checked}
                                                            onInput={this.handleChange} noValidate readOnly />I have read and understood the &nbsp;
                                                        <button type='button' onClick={() => this.openModal('terms')}
                                                            className={`btn ${styles.btn_link}`}>Terms &amp; Conditions,</button> &nbsp;
                                                        <button type='button' onClick={() => this.openModal('privacy')}
                                                            className={`btn ${styles.btn_link}`}>Privacy Terms,</button><br></br>&nbsp; &nbsp; &nbsp; &nbsp;
                                                        <button type='button' onClick={() => this.openModal('refund')}
                                                            className={`btn ${styles.btn_link}`}>Refund Policy</button> and the &nbsp;
                                                        <button type='button' onClick={() => this.openModal('works')}
                                                            className={`btn ${styles.btn_link}`}>How It Works?</button> and agree.
                                                    </label>
                                                    <div className={`${styles.error}`}>{this.state.checkError}</div>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col-md-12 text-right">
                                                    <ButtonStyle type="submit" className="btn" text={'Submit'} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.AboutUsCon} col-md-6 mt-2`}>
                            <div className={`${styles.AboutUs}`}>
                                <h3><strong>VISIONS:</strong></h3>
                                <ul>
                                    <li>To provide a Recession & Lockdown Proof Non-Stop Stable Online Income to Every INDIAN from the Online Advertisement Industry.</li>
                                    <li>To build INDIA ka Apna Website, an Indian Alternative for Indian Online Content Creators and Online Advertisers.</li>
                                    <li>To provide an online platform suitable for TINY BUSINESSES to Promote their Business using the Power of Online Advertisement. All-in-One Promotional Tool with options to post Unlimited FREE Pages, Videos & Photos.</li>
                                </ul>

                                <h3><strong>CONCEPT:</strong></h3>
                                <p>To increase the Purchasing Power of Consumers through the Online Advertisement Industry. Empower Yourself; Empower Others; Empower India. Let our children start to read "India is a developed country".</p>

                                <h3><strong>MOTTO:</strong></h3>
                                <p>Every Effort Made & Every Minute Spent to Earn Money Online Should Never Go Waste. Earn from the first content from the first day.</p>

                                <h3><strong>SLOGAN:</strong></h3>
                                <p>Ameeree Badaao.</p>

                                <h3><strong>INITIATIVE:</strong></h3>
                                <p><strong>“INDIA ka Apna Website”</strong><br></br>
                                    aajubaaju.in is built on the motive to contribute towards "Self Reliant India"; to provide an alternate online platform for Indian content creators and online advertisers. aajubaaju.in is a combination of user-generated content websites such as video streaming, photo story, blog, and review writing; combination of online directory websites such as business listing, event listing, and job posting websites. "A 7-in-1 Website."</p>

                                <h3><strong>SEARCH PLATFORM:</strong></h3>
                                <p>aajubaaju.in is fundamentally a "Highly Localized Information Providing Internet Platform" designed to store neighbourhood level information in 7 segments namely videos, photos, pages, reviews, business, events & jobs. A single search will yield information from 7 segments. aajubaaju.in uses the crowdsourcing model, "For the Indians; By the Indians" concept to source information in these 7 segments.</p>

                                <h3><strong>HOME SWEET HOME IS THE MANTRA:</strong></h3>
                                <p>aajubaaju.in aids "Vocal for Local" concept. aajubaaju.in focuses on the neighbourhood of a visitor. A visit to aajubaaju.in is designed to be an online tour of their neighbourhood. Visitors will see all the businesses around them, upcoming events around them, jobs around them, & advertisements of the businesses around them. The visitors will see all the information which can be used by them in their day-to-day life. The information will not just be useful information, but also usable information.</p>

                                <h3><strong>ONLINE EARNING FOR EVERYONE:</strong></h3>
                                <p>aajubaaju.in has developed a strategy to provide a "Recession & Lockdown Proof Stable Online Income Source" for everyone from the online advertisement industry. Anyone, according to their skill, can contribute content in one or more segments & earn from the advertisements displayed in their content. aajubaaju.in also has a strategy to earn by watching advertisements catering to the population having no skill in any of these 7 segments.</p>

                                <h3><strong>DIGITAL ENTREPRENEUR:</strong></h3>
                                <p>aajubaaju.in has a "Ab Sab ka Skills Hai Apna Skills" 2 levels loyalty earning program to provide digital entrepreneurship opportunities to set up huge online passive income.</p>
                                <ul>
                                    <li>Level 1 enables an AajuBaajuian to earn advertisement revenue share from others' content creation skills apart from earning from one's own skill. A video content creator can onboard a content writer, a photo story creator, a review writer, etc., to earn advertisement revenue share from their skills.</li>
                                    <li>Level 2 enables an AajuBaajuian to utilize others' socializing & influencing skills to increase their community size of other content creators.</li>
                                </ul>

                                <h3><strong>FOR TINY BUSINESSES:</strong></h3>
                                <p>Problem: The present online platforms for advertisements are either complex or expensive or both for the tiny businesses to use the power of online advertising to improve their business.</p>
                                <p>Solution: aajubaaju.in is suitable for tiny businesses to promote their business online within their locality with unlimited pages, unlimited photos & unlimited videos at zero cost. aajubaaju.in has a "Spend No More; Just Earn More" concept for business promotion. Apart from getting online visibility, businesses can earn advertisement revenue share from their promotions themselves to meet their promotional cost.</p>

                                <h3><strong>NO MORE HARDSHIPS TO EARN MONEY ONLINE:</strong></h3>
                                <p>aajubaaju.in has a motto of "Every Effort Made & Every Minute Spent to Earn Money Online Should Never Go Waste." aajubaaju.in has researched and eliminated the hardships causing wastage of effort and time spent to earn money online.</p>

                                <h3><strong>ELIMINATED THE HARDSHIPS OF VIDEO CONTENT CREATORS:</strong></h3>
                                <p>Problem: The major hardship for video content creators is the monetization rules prevailing in the leading advertisement revenue sharing platforms.</p>
                                <p>Solution: aajubaaju.in has no monetization rules such as minimum subscribers/page likes, minimum watch hours/followers, etc. Video content creators can post their video content in "AajuBaaju My Videos& AajuBaaju My Clips" segments and can start earning advertisement revenue share from their first video from the first day onwards. A video content creating AajuBaajuian can onboard other video content creators from multiple different niches and earn advertisement revenue share from their videos as well, as part of a loyalty earning program. An AajuBaajuian posting videos in the education niche can onboard a video content creator from the tech niche, beauty niche, cooking niche, etc., and earn loyalty earning.</p>

                                <h3><strong>ELIMINATED THE HARDSHIPS OF CONTENT WRITERS:</strong></h3>
                                <p>Problem: The major hardships for creative content writers are maintaining a website, researching keywords & SEO to drive traffic to their website, etc. Many content writers are unable to use their writing skills to earn money online because of these unwanted hurdles.</p>
                                <p>Solution: Creative content writers can post their articles and blog content in the "AajuBaaju My Pages" segment and can start earning advertisement revenue share from their first content from the first day onwards. Creative content writers can concentrate only on content writing now, their real skill. A creative content writing AajuBaajuian can onboard other creative content writers from multiple different niches and earn money from their writing as well, as part of the loyalty earning program. An AajuBaajuian writing in the education niche can onboard content writers from the tech niche, beauty niche, cooking niche, etc., and earn loyalty income.</p>

                                <h3><strong>NEW ADVERTISEMENT REVENUE SHARING SEGMENTS:</strong></h3>
                                <p>Problem: The present online earning opportunities require specific skill sets to earn money online. A large portion of the population does not have these specific skills.</p>
                                <p>Solution: aajubaaju.in has added additional advertisement revenue sharing segments to cover additional skill sets. aajubaaju.in has "AajuBaaju My Photos" segment in which an AajuBaajuian can create a photo story out of the photos taken on vacation, function, event, etc., or create product catalogues for their business and earn revenue share from the advertisements displayed in their photo story. aajubaaju.in has "AajuBaaju My Album" segment in which an AajuBaajuian can create a personal photo album out of the photos taken to capture memories and earn revenue share from the advertisements displayed in their photo album. aajubaaju.in has "AajuBaaju My Reviews" segment in which an AajuBaajuian can write a review about products, places, businesses, etc., and earn revenue share from the advertisements displayed in their review. aajubaaju.in has "Business Listings" segment in which an AajuBaajuian can list businesses, service providers, etc., and earn revenue share from the advertisements displayed in their business listing. aajubaaju.in has "Event Listings" segment in which an AajuBaajuian can list upcoming events and earn revenue share from the advertisements displayed in their event listing. aajubaaju.in has "Job Listings" segment in which an AajuBaajuian can list job vacancies and earn revenue share from the advertisements displayed in their job listing.</p>

                                <h3><strong>EARN BY WATCHING ADVERTISEMENTS:</strong></h3>
                                <p>Problem: There is a vast portion of the population still remaining who cannot make use of these 7 segments.</p>
                                <p>Solution: aajubaaju.in has created an "Easy to Learn" strategy to earn by watching advertisements to cover this portion of the population with zero skills.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="large_rect">
                    <div className={`${styles.RegistrationMob} low_padding`} key={'reg-form'}>
                        <TermsAndConditions closeModal={this.closeModal} terms={this.state.termsModel} />
                        <div className={`grid_container ${styles.classified_add}`} >
                            <form onSubmit={this.handleSubmit}>
                                <h2 className={`${styles.registration_header}`}>Registration Form</h2>
                                <div className='col-md-12 promoter_radio'>
                                    {/* <label className={`${styles.reg_form_section_label}`} htmlFor="title"><strong>Subscription Models With Target </strong></label>
                                    <div className="radio">
                                        <label className={`${styles.reg_form_section_label}`}>
                                            <input type="radio" name="account_type" value="option2" checked={this.state.selectedOption === 'option2'} onClick={() => this.openModal('quarter')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            &nbsp; Quarter Year** Subscription - Rs.1770/-*
                                        </label> <br></br>
                                    </div>
                                    <div className="radio">
                                        <label className={`${styles.reg_form_section_label}`}>
                                            <input type="radio" name="account_type" value="option3" checked={this.state.selectedOption === 'option3'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            &nbsp; Half Year** Subscription - Rs.3540/-*
                                        </label>
                                    </div>*/}
                                    <h2>About You</h2>
                                    <p>Please tick &#9989; all the applicable boxes.</p>
                                    <div className="row form-row col-md-12">
                                        <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                            <input type="checkbox" className={`${styles.form_check_input}`} name="business" age_checked={this.state.age_checked}
                                                onInput={this.handleChange} noValidate readOnly />I have business/businesses
                                        </label><br></br>
                                    </div>
                                    <div className="row form-row col-md-12">
                                        <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                            <input type="checkbox" className={`${styles.form_check_input}`} name="content" age_checked={this.state.age_checked}
                                                onInput={this.handleChange} noValidate readOnly />I can write content in English
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-12">
                                        <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                            <input type="checkbox" className={`${styles.form_check_input}`} name="video" age_checked={this.state.age_checked}
                                                onInput={this.handleChange} noValidate readOnly />I can create video content
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-12">
                                        <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                            <input type="checkbox" className={`${styles.form_check_input}`} name="photo" age_checked={this.state.age_checked}
                                                onInput={this.handleChange} noValidate readOnly />I can create photo content
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-12">
                                        <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                            <input type="checkbox" className={`${styles.form_check_input}`} name="influence" age_checked={this.state.age_checked}
                                                onInput={this.handleChange} noValidate readOnly />I can influence people
                                        </label>
                                    </div><br></br>
                                    <h2>Choose Your Subscription</h2>
                                    <div className="row form-row col-md-10">
                                        <label className='container_radio'>Half Year** Subscription - Rs.4500/-*
                                            {/* <img className='img-fluid' width='60px' src={limited} /> */}
                                            <input name="account_type" type="radio" value="option4" checked={this.state.selectedOption === 'option4'} onClick={() => this.openModal('paidHalf')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-10">
                                        <label className='container_radio'>Annual Subscription - Rs.9000/-*
                                            {/* Rs.6000/-* <strong>(Freedom Offer till 15 Aug)</strong> */}
                                            {/* Rs.6000/-* <img className='img-fluid' width='60px' src={goodbye} /> Rs.4500/-* <img className='img-fluid' width='60px' src={priviliege} /> */}
                                            <input name="account_type" type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onClick={() => this.openModal('paidFull')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-10">
                                        <label className='container_radio'>Unpaid Annual Subscription - Rs.9000/-*
                                            {/* Rs.6000/-* <img className='img-fluid' width='60px' src={goodbye} /> */}
                                            <br></br> Accumulate the Subscription and Upgrade
                                            <input name="account_type" type="radio" value="option5" checked={this.state.selectedOption === 'option5'} onClick={() => this.openModal('free')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <label className={`${styles.reg_form_section_label}`}>&nbsp;&nbsp;*( + 18% GST)
                                        <br></br>&nbsp; **Renewal will be in Annual Subscription.
                                        {/* <br></br>&nbsp; Note: The withdrawal option will not be available during Quarter Year Subscription Period. */}
                                    </label>
                                    <div className={`${styles.error}`}>{this.state.radioError}</div>
                                </div>
                                <div className={`${styles.reg_form_section}`}>
                                    <div className="form-group row">
                                        <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">Name  </label></div>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" placeholder="First name"
                                                name="firstName" required value={this.state.firstName} noValidate
                                                onChange={(event) => this.setState({ firstName: event.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" placeholder="Surname"
                                                name="lastName" required value={this.state.lastName} noValidate
                                                onChange={(event) => this.setState({ lastName: event.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">User name  </label></div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" placeholder="User name"
                                                name="username" required value={this.state.username} noValidate autoComplete="new-password"
                                                onChange={this.handleUserName}
                                            />
                                            <span className={`${styles.error}`}>{errors.username}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Password</label></div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" placeholder="Password"
                                                name="password" autoComplete="new-password" required
                                                value={this.state.password} noValidate
                                                onChange={(event) => { this.setState({ password: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.password}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="title">Confirm password  </label></div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" placeholder="Confirm password"
                                                name="confirmpassword" required value={this.state.confirmpassword} noValidate
                                                onChange={(event) => { this.setState({ confirmpassword: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.confirmpassword}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Mobile number</label></div>
                                        <div className="col-md-8">
                                            <input className="form-control" placeholder="Mobile number" noValidate maxLength="10"
                                                name="contact_number" required value={this.state.contact_number}
                                                onChange={(event) => { this.setState({ contact_number: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.contact_number}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Email address</label></div>
                                        <div className="col-md-8">
                                            <input className="form-control" placeholder="Email address" name="email_address"
                                                required value={this.state.email_address} noValidate
                                                onChange={(event) => { this.setState({ email_address: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.email_address}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Invitation key</label></div>
                                        <div className="col-md-8">
                                            {(window.location.href === window.location.origin + '/registration?ref=' + this.state.referralKey) ? <input className="form-control" placeholder="Enter invitation key"
                                                name="referralKey" value={this.state.referralKey} noValidate
                                                onChange={this.handleRefKey} readOnly
                                            /> :
                                                <input className="form-control" placeholder="Enter invitation key"
                                                    name="referralKey" value={this.state.referralKey} noValidate
                                                    onChange={this.handleRefKey}
                                                />}
                                            <span className={`${styles.error}`}>{errors.refKey}</span>
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Location</label></div>
                                        <div className="col-md-8">
                                            <SetAdvertiseLocation
                                                //heading='Areas Served' isMulti
                                                setFilterValidationMethod={this.setLocationValidationMethod}
                                                allLocations={this.state.fullAddress}
                                                selectedStates={this.state.selectedState}
                                                selectedCities={this.state.selectedCity}
                                                selectedLocalities={this.state.selectedLocality}
                                                selectedNeighbourhoods={this.state.selectedNeighbourhood}
                                                _handleState={this._handleState}
                                                _handleCity={this._handleCity}
                                                _handleLocality={this._handleLocality}
                                                _handleNeighbourhood={this._handleNeighbourhood}
                                                addressOptions
                                                setAddressOptions={this.setAddressOptions}
                                                labelClass={'col-md-8'}
                                                setStateValidationMethod={this.setStateValidationMethod}
                                                setCityValidationMethod={this.setCityValidationMethod}
                                                setLocalityValidationMethod={this.setLocalityValidationMethod}
                                                setNeighbourhoodValidationMethod={this.setNeighbourhoodValidationMethod}
                                            />
                                            <p className='location_p'>Tell us your location to see what is near you. (If your location is not available, please select your nearest location.)</p>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className={`${styles.form_check} col-md-12`}>
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="age_check" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I am above 18 Years of Age & Have a PAN.
                                            </label>
                                            <div className={`${styles.error}`}>{this.state.ageCheckError}</div>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className={`${styles.form_check} col-md-12`}>
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="tc" checked={this.state.checked}
                                                    onInput={this.handleChange} noValidate readOnly />I have read and understood the &nbsp;
                                                <button type='button' onClick={() => this.openModal('terms')}
                                                    className={`btn ${styles.btn_link}`}>Terms &amp; Conditions,</button> &nbsp;
                                                <button type='button' onClick={() => this.openModal('privacy')}
                                                    className={`btn ${styles.btn_link}`}>Privacy Terms,</button> &nbsp;
                                                <button type='button' onClick={() => this.openModal('refund')}
                                                    className={`btn ${styles.btn_link}`}>Refund Policy</button> and the &nbsp;
                                                <button type='button' onClick={() => this.openModal('works')}
                                                    className={`btn ${styles.btn_link}`}>How It Works?</button> and agree.
                                            </label>
                                            <div className={`${styles.error}`}>{this.state.checkError}</div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12 text-right">
                                            <ButtonStyle type="submit" className="btn" text={'Submit'} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>)
    }
}

export default Registration;