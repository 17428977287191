import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

export const AdvertisementPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="grid_container terms_of_service">
            <div className="row">
                <section className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>ADVERTISEMENT POLICIES</h1>
                    <ol type="I" className={FooterStyle.margin}>
                        <li>
                            <h2>UNDERSTANDING OUT POLICIES:</h2>
                            <p>We provide guidelines for what kinds of advertising content are permitted in our advertising policies. When advertising orders are placed, we review each ad that conflicts with these guidelines. If you believe that your advertisement was disapproved mistakenly, please notify us. Please remember that violating our Advertising Policies, Community Standards or other Aajubaaju policies and terms may result in being restricted from using Aajubaaju profiles, pages and accounts.</p>
                        </li>
                        <li>
                            <h2>THE FOLLOWING CONTENT IS FORBIDDEN:</h2>
                            <ol type="1">
                                <li>
                                    <h3>Standards of community</h3>
                                    <p>We make policy by listening to the comments of our community and taking the advice of experts in technology, public safety and human rights. In order to ensure that everyone's perspective is understood, we pay closer attention to design principles that are inclusive of different viewpoints and beliefs, including those who might be neglected.</p>
                                </li>
                                <li>
                                    <h3>Products or services those are illegal</h3>
                                    <p>A product, service, or piece of content that's inappropriate, illegal, or unsafe cannot be advertised to minors in simplified versions or by promoting illegal products, services, or activities.</p>
                                </li>
                                <li>
                                    <h3>Biased outcomes</h3>
                                    <p>Advertisements should not promote or further discrimination against individuals based on their racial, ethnic, or genetic characteristics such as their gender, age, neighborhood, or family status.</p>
                                </li>
                                <li>
                                    <h3>Tobacco products</h3>
                                    <p>Advertisements should not Promote and advertise tobacco products and smoking activities. An example would be: A tobacco product includes cigarettes, cigars, chewing tobacco, tobacco pipes, hookah, hookah lounges, rolling papers, vapor delivery devices, and electronic cigarettes, among others.</p>
                                </li>
                                <li>
                                    <h3>Narcotics &amp; its related products</h3>
                                    <p>Advertisements that promote the sale of or the use of illegal, prescription or recreational drugs are prohibited.</p>
                                </li>
                                <li>
                                    <h3>Firearms, ammunition, or explosives</h3>
                                    <p>It is extremely important that advertisements do not promote the sale or use of weapons, ammunition, or explosives, including advertising for accessories that modify weapons.</p>
                                </li>
                                <li>
                                    <h3>Products or services for adults</h3>
                                    <p>It is forbidden for advertising to feature any other adult product or service other than family planning and contraception. The advertisement of contraceptive products must be based on the product's characteristics, not stress sexual pleasure or enhancement, and it must be targeted at an audience with age 18 or older.</p>
                                </li>
                                <li>
                                    <h3>Adult content</h3>
                                    <p>Advertisements must not contain offensive, provocative, disrespectful, or excessively violent content.</p>
                                </li>
                                <li>
                                    <h3>Third party infringement</h3>
                                    <p>The content of advertisements cannot violate any third party's rights, including copyright, trademarks, privacy, promotional, or any other rights.</p>
                                </li>
                                <li>
                                    <h3>Attributes of a person</h3>
                                    <p>It is impermissible to include "before-and-after" photos or images that reveal unexpected outcomes. Advertisement content about diets, weight loss, or other health-related products should not be designed to create negative self-perception.</p>
                                </li>
                                <li>
                                    <h3>Controversial matter</h3>
                                    <p>Advertisements may not contain commercial content exploiting controversial societal or political issues.</p>
                                </li>
                                <li>
                                    <h3>Fraudulent and cheating practices</h3>
                                    <p>It is not allowed to advertise or promote products or services that encourage cheating or deceitful behavior.</p>
                                </li>
                                <li>
                                    <h3>Buying and selling body parts</h3>
                                    <p>Advertisements may not promote the sale of parts of the body or fluids of humans and animals.</p>
                                </li>
                                <li>
                                    <h3>Content containing sexual content</h3>
                                    <p>To ensure that advertisers' advertisements and destinations comply with user preferences and laws, we do not allow certain types of sexual content.  It is allowed to display some ad content that has sexual references if it adheres to the policies and does not target minors, but these advertisements will only appear in limited situations based on user search queries, user age, and local law where the advertisement is being served.</p>
                                </li>
                                <li>
                                    <h3>Alcohol</h3>
                                    <p>As specified by AajuBaaju's guidelines, we don't encourage the sales of alcohol on our website.</p>
                                </li>
                                <li>
                                    <h3>Dating</h3>
                                    <p>As specified by AajuBaaju's guidelines, we don't permit any dating activities on our website.</p>
                                </li>
                                <li>
                                    <h3>Game playing and gambling</h3>
                                    <p>The advertising of online real money casinos, sportsbooks, card games, or even poker, can be done with or without prior written permission, just as the advertising of a real money lottery. The games created of gambling, skill, and lottery advertising that are approved for 18-year-olds or older who are within the state or jurisdiction that grants the permission.</p>
                                </li>
                                <li>
                                    <h3>Healthcare and medicines</h3>
                                    <p>Our company is committed to adhering to health and medicine advertising standards, so we expect our advertisers and destinations to follow those standards. Advertising healthcare-related content isn't permitted for some products but is allowed if the advertiser has certification with AajuBaaju.</p>
                                    <p>A trademark owner may file a complaint regarding how AajuBaaju advertisements utilizes a trademark. If we enforce certain restrictions on such use, AajuBaaju advertisements will not be able to use that trademark.</p>
                                </li>
                                <li>
                                    <h3>Copyrights &amp; Trademarks</h3>
                                    <p>In accordance with local trademark laws, AajuBaaju advertisements must not infringe on the trademark rights of third parties. Resellers, for instance, are allowed to use trademarks on their products under certain circumstances.</p>
                                </li>
                                <li>
                                    <h3>Legal requirements</h3>
                                    <p>To follow the standard AajuBaaju advertisement policies, we expect advertisers to adhere to the local laws of the areas where their advertisements appear. Our rule of thumb is to apply this policy as strictly as possible because we do not wish to allow the content of questionable legality.</p>
                                </li>
                                <li>
                                    <h3>Other restricted businesses</h3>
                                    <p>Even though businesses may appear to follow our other policies, we prohibit certain types of businesses from advertising with us to keep our users safe from exploitation. We occasionally identify products or services that are prone to abuse based on our own continuous reviews, as well as feedback from users, regulators, and consumer protection authorities. Some kinds of businesses may cause customer safety or the user experience to be compromised if we feel they pose an unreasonable risk.</p>
                                </li>
                                <li>
                                    <h3>Restricted advertisement formats and features</h3>
                                    <p>AajuBaaju advertisements’ advanced advertising formats and features are determined by a variety of factors. You are not eligible to use certain advertisement formats and features until you meet the requirements or have been certified to use them.</p>
                                    <p>Documentation may be required as proof you have been authorized permission to use the product features, or you have demonstrated sustained policy compliance for some time. The standard AajuBaaju advertisement policies affect all formats and features.</p>
                                </li>
                                <li>
                                    <h3>Branded content</h3>
                                    <p>Promoted third-party products, brands, or business partners must be tagged in advertisements with the Branded Content Tool. Manufacturers or publishers can create brand content by showing or influencing the content of business partners. When promoting the use of branded content, advertisers should use the Branded Content tool.</p>
                                </li>
                            </ol>
                            <li>
                                <h2>A LIST OF PROHIBITED PRACTICES:</h2>
                                <ol type="1">
                                    <li>
                                        <h3>Abusing the advertising network</h3>
                                        <p>Neither we nor our advertisers will accept advertisements, content, or destinations that attempt to circumvent or trick our ad review process.</p>
                                        <p>Advertisement network misuses include promoting content that contains malware, cloaking or using other techniques to disguise the destination that users are being directed to, or arbitraging to promote destinations that serve primarily as advertisements; selling "gateway" or "bridge" destinations, to send users elsewhere; promoting social media advertisements for the sole purpose of gaining their public endorsement; and manipulating policy settings to bypass our policy review system.</p>
                                    </li>
                                    <li>
                                        <h3>Collecting and utilizing data</h3>
                                        <p>In order to establish trust, we want users to feel confident that their information is respected and handled responsibly. This information must not be misused or collected for unclear purposes by our advertising partners nor without appropriate disclosures or security measures.</p>
                                        <p>The following information should be handled carefully: full name; email address; mailing address; phone number; national identity, pension, aadhar number, GST number, PAN, tax identification number, or health insurance number; birth date; political affiliation; sexual orientation; race or ethnicity; religion; financial status; and financial status information.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h2>REQUIREMENTS FOR TECHNICAL AND EDITORIAL ASPECTS:</h2>
                                <p>AajuBaaju adheres to high editorial and professional standards in order to provide its users with a quality user experience. Advertisements on the site are limited to those that appear clearly, appropriately, and lead users to content that is useful, relevant, and easy to use.</p>
                                <h3>Format requirements for advertisements</h3>
                                <p>We only allow advertisements that meet specific requirements so you can deliver attractive, professional-looking advertisements that provide a great user experience. Make sure you're meeting the requirements for all advertisement formats.</p>
                                <p>Example of an ad format requirement: character limits in the headline or body, image size requirements, video length limits, aspect ratio requirements, etc.</p>
                            </li>
                            <li>
                                <h2>LEADING STATEMENTS:</h2>
                                <p>We do not approve the use of lead advertising questions that request the following types of information.</p>
                                <ol type="1">
                                    <li>
                                        <h3>Requesting account numbers</h3>
                                        <p>You shouldn't ask for frequent call numbers, loyalty card numbers, or account numbers with cable or telephone account numbers without our approval.</p>
                                    </li>
                                    <li>
                                        <h3>Inquiring about criminal history</h3>
                                        <p>Please do not ask for details of criminal or arrest histories without our consent.</p>
                                    </li>
                                    <li>
                                        <h3>Requesting financial information</h3>
                                        <p>Advertisers may not request any financial information about you except with your permission, such as bank account numbers, bank routing numbers, credit/debit card numbers, PAN, credit scores, income, and net worth.</p>
                                    </li>
                                    <li>
                                        <h3>Government-issued identification symbols</h3>
                                        <p>Advertising must not ask for government-issued identification such as aadhar number, passport number, or driver's license number without our prior consent.</p>
                                    </li>
                                    <li>
                                        <h3>Inquiring about religion</h3>
                                        <p>Advertisements are not permitted to solicit information about religious or philosophical beliefs without our permission.</p>
                                    </li>
                                    <li>
                                        <h3>Inquiring about sexual orientation</h3>
                                        <p>Advertisements should not ask about the person's sexual orientation or history or ask what gender he/she prefers to date, without our prior permission.</p>
                                    </li>
                                    <li>
                                        <h3>Requesting usernames and passwords</h3>
                                        <p>The advertising side may not request usernames or passwords, including those for new accounts and accounts already in existence, without our prior permission.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h2>THINGS YOU NEED TO KNOW:</h2>
                                <p>Advertisers must comply with all laws and regulations. You may be subject to a variety of consequences if you fail to comply, including being withdrawn from the ad and being removed from your account.</p>
                                <p>You don't need to worry about sensitive information being used for advertising AajuBaaju doesn't display advertisements related to the personal beliefs, features, or values of its users.</p>
                                <p>If we feel an advertisement will negatively affect our relationship with users, our competitive position, interests, or advertising philosophy, we reserve the right to reject, approve, or delete the advertisement.</p>
                                <p>Occasionally, policies may change all of a sudden. We listen to user’s concerns and feedback to ensure a positive user experience. Additionally, we regularly review changes in online trends and practices, industry norms, and regulations and when we draft policies, we consider our company's values and culture, as well as the operational, technical, and business implications.</p>
                            </li>
                        </li>
                    </ol>
                </section>
                <footer className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </footer>
            </div>
        </main >
    )
}

