import React, { Component } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

class OfcAddress extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <main className="grid_container terms_of_service">
                <div className="row">
                    <section className="col-md-12">
                        <h1 className={`${FooterStyle.footer_links_header} text-center`}>Our Offices</h1><br></br><br></br><br></br>
                        <div className="row">
                            <div className="col-sm-4">
                                <p><strong>Registered Office:</strong><br /> H.No.3, Kalyanpuri Colony,<br /> Sainikpuri Post,<br />Secunderabad - 500094<br />
                                    Telangana, INDIA.<br />
                                    Email - support@aajubaaju.in</p>
                            </div>
                            <div className="col-sm-8">
                                <p><strong>Marketing Office:</strong><br /> Office No. 306, 3rd Floor, Spellbound Towers,<br /> Plot No. 147, Hi-Tension Road, O.U.T. Colony,<br />
                                    Sainikpuri, Secunderabad - 500094,<br />
                                    Telangana, INDIA.<br />Mobile - 8341370819 - General Enquiries and Marketing Queries. Timings 10am to 7pm.<br />Mobile - 6304704362 - User Account Related Queries. Timings 2pm to 9pm<br />Landline - 040-48564079 - Adveritisement and Sponsorship Queries. Timings 10am to 7pm<br /></p>
                            </div>
                        </div><br></br>
                        <p>If you are a guest user, send us a message in non-office hours and we will call you back.<br />
                    If you are an AajuBaajuian, Click on CONTACT SUPPORT FORM to let us know your query.</p>
                    </section>
                    <footer className="listings_footer row">
                        <div className={`${styles.large_rect}`}>
                            <LargeRectangle spot={"-1"} />
                        </div>
                        <div className={`${styles.billBoard}`}>
                            <BillBoard spot={"-1"} />
                        </div>
                    </footer>
                </div>
            </main>
        )
    }
}
export default OfcAddress;