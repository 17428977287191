import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const Advt = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="grid_container terms_of_service">
            <div className="row">
                <section className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>EARN ADDITIONAL INCOME ONLINE BY WATCHING ADVERTISEMENTS</h1>
                    <ul>
                        <li>
                            <h2>TYPES OF ADVERTISEMENTS:</h2>
                            <img className="img-fluid" src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/advtAajuBaaju.jpg"} alt='Types of Ads image' />
                            <p>There are 5 types of advertisements in aajubaaju.in namely</p>
                            <p><span className={FooterStyle.advt_font}>Leaderboard:</span><br></br>
                                The leaderboard advertisement will be displayed in the header.
                            </p>
                            <p><span className={FooterStyle.advt_font}>Banner:</span><br></br>
                                The banner advertisement will be displayed below the header.
                            </p>
                            <p><span className={FooterStyle.advt_font}>Large Rectangle:</span><br></br>
                                The large rectangle advertisement will be displayed in the right column and in the left column on login and registration pages.
                            </p>
                            <p><span className={FooterStyle.advt_font}>Billboard:</span><br></br>
                                The billboard advertisement will be displayed inline in the content.
                            </p>
                            <p><span className={FooterStyle.advt_font}>Popup:</span><br></br>
                                When a user clicks any post to view the listing, the advertisement will be displayed in a popup.
                            </p>
                        </li>
                        <li>
                            <h2>HOW TO WATCH ADVERTISEMENTS:</h2>
                            {/* <div>
                                <video className={FooterStyle.advt_video} height="auto" controls>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Advertisements.mp4"} type="video/mp4" />
                                </video>
                            </div> */}
                            <p>Every advertisement will have AD in the top right corner to let you know that it is an advertisement.</p>
                            <p>Each advertisement displayed has to be watched for 30 seconds until you see a green tick mark in the bottom right corner.</p>
                            <p>Repeatedly visit all the pages namely Home, Contact, Help, Footer Links, User Management, Form and Listing pages to find advertisements.</p>
                            <p>To explain in detail:</p>
                            <ol type='none'>
                                <p>1. When you visit aajubaaju.in you will be on home page. Complete watching all the advertisements displayed in the home page.</p>
                                <p>2. Once all the advertisements get the tick mark in the home page, click on “Contact Support” link on the top left corner of the header. Complete watching all the advertisements displayed in the contact support page.</p>
                                <p>3. Once all the advertisements get the tick mark in the contact support page, click on “Back to Home” link on the top left corner of the header. Complete watching all the advertisements displayed in the home page.</p>
                                <p>4. Once all the advertisements get the tick mark in the home page, again click on “Contact Support” link on the top left corner of the header. If you notice that there are no advertisements displayed in the contact support page, click on “Help” link on the top right corner of the header. Complete watching all the advertisements displayed in the help page.</p>
                                <p>5. Likewise, keep visiting all the pages repeatedly to complete watching all the advertisements.</p>
                            </ol>
                        </li>
                        <li>
                            <p>Click the listings listed in the home page in all the sections. The advertisements will display if the particular listing clicked has Eligibility of Advertisements Display.</p>
                            <p>To explain in detail:</p>
                            <ol type='none'>
                                <p>1. After you complete watching all the advertisement by visiting all the pages repeatedly, start to click on the listings posted in the home page one by one.</p>
                                <p>2. You will see advertisements displayed in the listing page when you click on a listing including one advertisement displaying in a popup in 5 seconds after visiting the listing page.</p>
                                <p>3. Sometimes, you will not see any advertisements displayed in a listing page you visited. That does not mean that you have watched all the advertisements you could have watched in the listing pages. No advertisements are displayed in that particular listing because that listing does not have the Eligibility of Advertisements Display.</p>
                                <p>4. Complete clicking all the listings listed in the home page.</p>
                            </ol>
                        </li>
                        <li>
                            <p>Search in different categories and sub-categories to find advertisements.</p>
                            <p>To explain in detail:</p>
                            <p>Select a category and a sub-category and click on the Lookup Button. You will visit the lookup page.</p>
                            <ol type='none'>
                                <p>1. Start to click on the listings displayed in the lookup page one by one.</p>
                                <p>2. You will see advertisements displayed in the listing page when you click on a listing including one advertisement displaying in a popup in 5 seconds after visiting the listing page.</p>
                                <p>3. Sometimes, you will not see any advertisements displayed in a listing page you visited. That does not mean that you have watched all the advertisements you could have watched in the listing pages. No advertisements are displayed in that particular listing because that listing does not have the Eligibility of Advertisements Display.</p>
                                <p>4. Complete clicking all the listings listed in the lookup page.</p>
                            </ol>
                            <p>Watch until all the advertisements complete. If you have not watched at least 135 advertisements, you have not found all the advertisements.</p>
                            <p>To learn more about how to earn additional income online by watching advertisements, send a whatsapp message to 8341370819.</p>
                        </li>
                    </ul>
                    <section className='row pb-1'>
                         {/* style={{paddingBottom:'5px'}}> */}
                        <div className='col-md-9'></div>
                        <div className="col-md-3 add_button animated_back_button">
                            <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                        </div>
                    </section>
                </section>
                <footer className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </footer>
            </div>
        </main>
    )
}

