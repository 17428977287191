import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

export const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="grid_container terms_of_service">
            <div className="row">
                <section className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>PRIVACY POLICY</h1>
                    <ol type="I" className={FooterStyle.margin}>
                        <li>
                            <h2>PRIVACY STATEMENT:</h2>
                            <p>This privacy policy (the "PRIVACY POLICY") applies to your use of the website (here-in after called as “PLATFORM”) AajuBaaju eClassifieds Private Limited hosted at aajubaaju.in, the Services (as defined under the aajubaaju.in "Terms & Conditions") but does not apply to any third party websites that may be linked to them, or any relationships you may have with the businesses listed on aajubaaju.in.</p>
                            <p>This privacy policy sets out how AajuBaaju eClassifieds Private Limited uses and protects any information that you provide to AajuBaaju eClassifieds Private Limited when you use this platform.</p>
                            <p>AajuBaaju eClassifieds Private Limited is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this platform, then you can be assured that it will only be used in accordance with this privacy statement.</p>
                        </li>
                        <li>
                            <h2>WHO ARE WE?</h2>
                            <p>AajuBaaju eClassifieds Private Limited (here-in after called as “aajubaaju.in”), a company incorporated and registered in India, is the data controller for the personal data collected through this Platform.</p>
                            <p>The terms "WE", "OUR" and "US" refer to aajubaaju.in and the terms "YOU", "YOUR" and "USER" refer to you, as a user of aajubaaju.in. The term "PERSONAL INFORMATION" means information that you provide to us which personally identifies you to be contacted or identified, such as your name, phone number, email address, and any other data that is tied to such information.</p>
                        </li>
                        <li>
                            <h2>WHAT WE COLLECT?</h2>
                            <p>The kinds of personal information we collect about you may include:</p>
                            <ol type="a">
                                <li>
                                    <p>Your name</p>
                                </li>
                                <li>
                                    <p>Your email address and an alternate email address</p>
                                </li>
                                <li>
                                    <p>Your mobile number and an alternate mobile number</p>
                                </li>
                                <li>
                                    <p>Your address including house/plot no. with street name, neighbourhood, locality, city, state and pincode.</p>
                                </li>
                                <li>
                                    <p>Your bank account details including bank name, your name as in the bank account, branch name and IFSC code.</p>
                                </li>
                                <li>
                                    <p>Your PAN number</p>
                                </li>
                                <li>
                                    <p>Your GST number</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>HOW WE COLLECT PERSONAL INFORMATION?</h2>
                            <p>The main circumstances in which aajubaaju.in collects personal information about you are:</p>
                            <ol type="1">
                                <li>
                                    <h3>When you provide it to us:</h3>
                                    <p>If you register as an AajuBaajuian in aajubaaju.in, by creating an account, you will provide information to us as part of your registration. You may also give us information when you contribute user generated content. For information about our collection and retention of payment information see below.</p>
                                    <ol type="none">
                                        <li>
                                            <h4>Payment information:</h4>
                                            <p>If you use Razorpay to complete your transaction, your personal information (including credit card information) will be collected and used by Razorpay. For more information, you can read the Razorpay Privacy Policy here. (https://razorpay.com/privacy/)</p>
                                            <p>You will provide us your bank account details to receive your earnings. We store it in our database until you use aajubaaju.in for your contributions and earnings. Your bank account details will also be added to the beneficiary list of AajuBaaju eClassifieds Private Limited’s current account held with HDFC Bank.</p>
                                            <p>You will provide us your PAN number to deduct TDS on your earnings.</p>
                                            <p>You will provide GST number, in case you need GST invoice.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h3>When someone else provides it to us on your behalf:</h3>
                                    <p>Sometimes someone else such as a parent, carer or friend might provide us with information about you so that we can provide you with products or services. For example, someone else may provide us with information about you when creating an account for you on aajubaaju.in.</p>
                                </li>
                                <li>
                                    <h3>When we collect it in relation to your use of digital services:</h3>
                                    <p>We also collect information about your use of aajubaaju.in through the use of technologies like cookies, beacons and local storage.</p>
                                    <ol type="none">
                                        <li>
                                            <h4>Device Information:</h4>
                                            <p>We store a unique identifier, date time and login data. We associate the device identifiers with your aajubaaju.in account.</p>
                                        </li>
                                        <li>
                                            <h4>Location information:</h4>
                                            <p>Depending on your device permissions, if you post an item on aajubaaju.in, we automatically collect and process information about your actual location. We use various technologies to determine location, including IP address, GPS, Wi-Fi access points and mobile towers. Your location data allows you to see user items near you and helps you in posting items within your location. For this purpose, Google Maps is integrated into aajubaaju.in. This means that both Google and we are responsible for the processing of your location data in the context of Google Maps. In any case, we will not process your location data for any other purposes but those explained in this Privacy Statement. However, Google might process such location data for their own purposes as explained in the Google Privacy Policy which can be reviewed Your use of Google Maps through our Platform is subject to Google Maps’ Terms of Service. In case we need your location data, we will first show you a pop-up which will ask you to choose to allow or not to allow us to access your location data. If you do not allow us to have access to your location data, you will still be able to use our Services but with limited functionality. If you do allow us to access your location data you can always change this later by going to the settings on your browser settings and disable the permissions related to location sharing.</p>
                                        </li>
                                        <li>
                                            <h4>Client and Log data:</h4>
                                            <p>We will store technical details, including the Internet Protocol (IP) address of your device, time zone, operating system and type & version of your browser. We will also store your login information (registration date, date of last password change, date of the last successful login).

                                            </p>
                                        </li>
                                        <li>
                                            <h4>Cookies and Similar Technologies:</h4>
                                            <p>We use cookies to manage our users’ sessions, to store your preference language selection and deliver you relevant advertisements. "Cookies" are small text files transferred from a web server to the hard drive of your device. Cookies may be used to collect the date and time of your visit, your browsing history, your preferences, and your username. You can set your browser to refuse all or some cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our Services/Platform may become inaccessible or not function properly. For more information on the cookies we use, please see our Policy on Cookies and Similar Technologies.</p>
                                            <p>When you use aajubaaju.in, these technologies may be used to improve your user experience.</p>
                                            <p>If you are not a registered aajubaaju.in account holder, then we will track this information by reference to digital information such as an IP address, or an identifier for your device or browser, and will not seek to identify you.</p>
                                            <p>If we do not collect information about you or your use of aajubaaju.in, we may not be able to provide you with certain digital services, or we may not be able to tailor or enhance your user experience.</p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>USE AND DISCLOSURE OF PERSONAL INFORMATION:</h2>
                            <p>The purposes, for which we collect, use and disclose personal information include:</p>
                            <p>We will not send you unsolicited direct marketing emails, without your prior consent, unless we are permitted to do so by law. We will always give you the option to opt out of further communications.  We may send you electronic communications for account administration purposes for so long as you are a registered user of aajubaaju.in.</p>
                            <ol type="1">
                                <li>
                                    <p>to activate your account by validating your mobile number and email address provided in the registration form;</p>
                                </li>
                                <li>
                                    <p>to enhance our content and services using your contributions;</p>
                                </li>
                                <li>
                                    <p>to serve our audiences with personalised and relevant content;</p>
                                </li>
                                <li>
                                    <p>to manage our relationship with you, including:</p>
                                    <ol type="a">
                                        <li>
                                            <p>communicating with you, including providing information about your activities, sending notifications such as policy changes, feature enhancements, etc;</p>
                                        </li>
                                        <li>
                                            <p>billing you for any products or services that we charge for;</p>
                                        </li>
                                        <li>
                                            <p>investigating any complaints made by or about you;</p>
                                        </li>
                                        <li>
                                            <p>otherwise offering you assistance, including, by providing technical support;</p>
                                            <ul>
                                                <li>
                                                    <p>• to moderate user content;</p>
                                                </li>
                                                <li>
                                                    <p>• for statistical and research purposes;</p>
                                                </li>
                                                <li>
                                                    <p>• if permitted or required by law;</p>
                                                </li>
                                                <li>
                                                    <p>• otherwise with your consent; or</p>
                                                </li>
                                                <li>
                                                    <p>• for purposes directly related to any one or more of the above.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>HOW WILL WE INFORM YOU ABOUT CHANGE IN OUT POLICY STATEMENT?</h2>
                            <p>We may amend and update this privacy statement from time to time. We will notify you of material changes to this privacy statement as appropriate under the circumstances and as required by applicable laws, e.g. by placing a prominent notice within our Services or by sending you a message via our Services or by sending you an email. If you do not agree with the way we are processing your personal data and the choices we are providing to you, you may close your account at any time by sending an email to support@aajubaaju.in to delete your account.</p>
                        </li>
                        <li>
                            <h2>COMMUNICATION:</h2>
                            <p>We will communicate with you by email, SMS or in the app notification in connection with our Services/Platform to confirm your registration, to inform you in case your ad listing has become live/expired and other transactional messages in relation to our Services. As it is imperative for us to provide you such transactional messages you may not be able to opt-out of such messages.</p>
                        </li>
                        <li>
                            <h2>WHO DO WE SHARE YOUR DATA WITH?</h2>
                            <p>Third Party Service Providers:  We use third party service providers to help us deliver certain aspect of our Services for example, cloud storage facilities.</p>
                            <p>Law enforcement authorities, regulators and others: We may disclose your personal data to law enforcement authorities, regulators, governmental or public bodies and other relevant third parties comply with any legal or regulatory requirements.</p>
                            <p>Publicly available information: When you post a listing in aajubaaju.in, you may choose to make certain personal information visible to other aajubaaju.in users. This may include your first name, last name, your email address, your location and your contact number. Please note, any information you provide to other users can always be shared by them with others so please exercise discretion in this respect.</p>
                        </li>
                        <li>
                            <h2>WHERE DO WE STORE YOUR DATA AND FOR HOW LONG?</h2>
                            <p>The data we collect about you will be stored and processed in secure servers in order to provide the best possible user experience. For example – for fast website or mobile application build up.</p>
                            <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                        </li>
                        <li>
                            <h2>TECHNICAL AND ORGANIZATIONAL MEASURES AND PROCESSING SECURITY:</h2>
                            <p>All the information we receive about you are stored on secure servers. In addition, we ensure that all payment data are encrypted using SSL technology.</p>
                            <p>Please note, despite the measures we have implemented to protect your data, the transfer of data through the Internet or other open networks is never completely secure and there is a risk that your personal data may be accessed by unauthorized third parties.</p>
                        </li>
                        <li>
                            <h2>LINKS TO THIRD-PARTY WEBSITES?</h2>
                            <p>aajubaaju.in may contain links to third party websites or apps. If you click on one of these links, please note that each one will have its own privacy policy. We do not control these websites/apps and are not responsible for those policies. When you leave aajubaaju.in, we encourage you to read the privacy notice of every website you visit.</p>
                        </li>
                        <li>
                            <h2>HOW DO I MANAGE OR DELETE INFORMATION ABOUT ME?</h2>
                            <p>We give you the ability to access, edit, and remove your data in your profile setting.</p>
                            <p>You may also close your account at any time by sending an email to support@aajubaaju.in to block your account.</p>
                            <p>When you request to block your account, you can remove your personal information from the following sections namely Personal Information, Contact Information (except Registered Phone Number and Registered Email fields) and Payment Account Information (except PAN) and you will never be able to restore that information again. The content and advertisements posted by you will be available in aajubaaju.in until its expiry. Note: The profile photo, registered phone number, registered email ID and PAN will be stored for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                        </li>
                        <li>
                            <h2>MAKING A COMPLAINT:</h2>
                            <p>aajubaaju.in welcomes feedback about privacy issues and will attend to all questions and complaints promptly. Please send an email to support@aajubaaju.in.</p>
                        </li>
                    </ol>
                </section>
                <footer className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </footer>
            </div>
        </main>
    )
}

