import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const Referral = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="grid_container terms_of_service">
            <div className="row">
                <section className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>EARN PASSIVE INCOME ONLINE BY LOYALTY EARNING</h1>
                    <ul>
                        <li>
                            <h2>LOYALTY EARNING PROGRAM:</h2>
                            <p>“aajubaaju.in” runs a loyalty earning program from which the AajuBaajuians can earn a passive income. The loyalty earning program has 2 levels, namely Level 1 Loyalty Earnings and Level 2 Loyalty Earnings.</p>
                            {/* <div>
                                <video className={FooterStyle.advt_video} height="auto" controls>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Referring.mp4"} type="video/mp4" />
                                </video>
                            </div> */}
                        </li>
                        <li>
                            <h2>LEVEL 1:</h2>
                            <p>“Summer” becomes an AajuBaajuian by paying the annual subscription.<br></br>
                                “Summer” invites her friend “Winter” to become an AajuBaajuian by paying the annual subscription.<br></br>
                                Now “Winter” is a Level 1 of “Summer”
                            </p>
                        </li>
                        <li>
                            <h2>LEVEL 1 LOYALTY EARNING:</h2>
                            <p>“Summer” will become eligible to earn Level 1 Loyalty Earning which will be equal to 1/3rd of “Winter&#39;s” earning<br></br>
                                i.e., When “Winter” earns Rs.3000/- in a month, “Summer” will become eligible for Level 1 Loyalty Earning of Rs.1000/- <br></br>
                            </p>
                        </li>
                        <li>
                            <h2>LEVEL 2:</h2>
                            <p>“Winter” invites her friend “Spring” to become an AajuBaajuian by paying the annual subscription.<br></br>
                                Now “Spring” is Level 1 of “Winter” and “Spring” is Level 2 of “Summer”
                            </p>
                        </li>
                        <li>
                            <h2>LEVEL 2 LOYALTY EARNING:</h2>
                            <p>“Winter” will become eligible to earn a Level 1 Loyalty Earning which will be equal to 1/3rd of “Spring&#39;s” earning. “Summer” will also become eligible to earn Level 2 Loyalty Earning which will be equal to 1/3rd of “Spring&#39;s” earning.<br></br>
                                i.e., When “Spring” earns Rs.3000/- in a month, both “Winter” and “Summer” will become eligible for Level 1 Loyalty Earning and Level 2 Loyalty Earning of Rs.1000/- respectively.
                            </p>
                        </li>
                        <li>
                            <h2>LOYALTY EARNING TARGET:</h2>
                            <p>An AajuBaajuian should complete the following target to Withdraw “LOYALTY EARNING of every Rs.50,000/-”:</p>
                            <p>Must Post 30 Free Listings<br></br>
                                Must Post 2 Paid Listings<br></br>
                                Must Post 2 Advertisements<br></br>
                            </p>
                            <p>To learn more about how to earn passive income online by inviting, send a whatsapp message to 8341370819.</p>
                        </li>
                    </ul>
                    <div className='row pb-1'>
                         {/* style={{ paddingBottom: '5px' }}> */}
                        <div className='col-md-9'></div>
                        <div className="col-md-3 add_button animated_back_button">
                            <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                        </div>
                    </div>
                </section>
                <footer className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </footer>
            </div>
        </main>
    )
}

