import React, { Component, lazy, Suspense } from 'react';
import axios from 'axios';
import Geocode from 'react-geocode';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import { validEmailRegex, mobilenumberRegex, phonenumberRegex, address, zipCode, webSiteDomain, personName, validateForm, searchFilterNames } from '../../RegxConst'
import { DatePicker, TimePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import BillBoard from '../../advertisements/BillBoard';
import LargeRectangle from '../../advertisements/LargeRectangle';
//import LargeRectangle from '../../advertisements/LargeRectangle';
import authservice from '../../Authentication/service/authservice';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_ERROR } from '../../services/ABeCProperties';
import ConfirmPopup from '../../ConfirmPopup';
import { AnimatedText } from '../../AnimatedText';
import { notifyUser, type as notificationType, type } from '../../utility/notifyUser';
import Loader from '../../utility/Loader';
import styles from './addpost.module.css'
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import { FormLabels } from '../../UIElements/Form_label/FormLabels';
import { Errors } from '../../UIElements/errors/Errors';
import { FileInput } from '../../UIElements/input/FileInput';
import { PackageSelectors } from './ui-components/PackageSelectors';
import MyVideoInput from './MyVideoInput';
import MyPagesInput from './MyPagesInput';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import listingService from '../service/listingService';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const SetCategory = lazy(() => import('../../SetFilters/SetCategory/SetCategory'));
const SetLocation = lazy(() => import('../../SetFilters/SetLocation/SetLocation'));

class AddPost extends Component {
    constructor(props) {
        super(props);
        this.currentElement = null
        this.currentUser = authservice.getCurrentUserName();
        this.addressOptions = [];
        this.selectedNeighbourhood = [];
        this.selectedLocality = [];
        this.selectedCity = [];
        this.selectedState = [];
        this.selectedCategories = [];
        this.selectedSubCategories = [];
        this.categoryValidation = null;
        this.subCategoryValidation = null;
        this.stateValidation = null;
        this.cityValidation = null;
        this.localityValidation = null;
        this.neighbourhoodValidation = null;
        this.selectRef = React.createRef();
        this.selectDateType = this.selectDateType.bind(this);
    }
    state = {
        editorState: EditorState.createEmpty(),
        myPages: '',
        myVideos: '',
        myClips: '',
        myReviews: '',
        myPhotos: '',
        myAlbum: '',
        isDisabled: false,
        selectedDateType: null,
        readOnly: false,
        hpId: '',
        package: '',
        business_name: '',
        thumbnail_description: '',
        specialized_in: '',
        mypages_content: '',
        myvideos_content: '',
        landmark: '',
        contact_person: '',
        contact_number: '',
        email_address: '',
        website: '',
        status: "",
        selectedListingType: "",
        selectedCategory: [],
        selectedSubCategory: [],
        selectedNeighbourhood: [],
        selectedLocality: [],
        selectedCity: [],
        selectedState: [],
        fullAddress: [],
        allCategories: [],
        listingTypeOptions: [],
        editorData: [],
        errors: {
            address: '',
            email_address: '',
            contact_person: '',
            contact_number: '',
            website: '',
            invalidTime: '',
            thumbnailImageSizeError: '',
            address_info: '',
            description: '',
            uploadError: '',
            sub_Categories: '',
        },
        selectedListing: 'business',
        experience: '',
        salary: '',
        date: null,
        time: null,
        companyName: "",
        thumbnail_image: null,
        address_text: '',
        thumbnail_image_url: null,
        thumbnail_image_name: '',
        elements: [],
        dateAndTimeElements: [],
        inValidFromTime: false,
        inValidToTime: false,
        openFrom: false,
        openTo: false,
        showConfirmationPopup: false,
        elementStatus: {},
        selectedDateType: 'dateTime',
    };
    time = {
        weekday: '',
        open: '',
        close: ''
    }
    deletedElements = [];
    deletedDateAndTimeElements = [];

    listingType = () => {
        return [
            { label: 'Business Listing', value: 'business' },
            { label: 'Event Listing', value: 'event' },
            { label: 'Job Listing', value: 'job' },
            { label: 'AajuBaaju My Pages', value: 'news' },
            { label: 'AajuBaaju My Videos', value: 'article' },
            { label: 'AajuBaaju My Clips', value: 'clips' },
            { label: 'AajuBaaju My Reviews', value: 'reviews' },
            { label: 'AajuBaaju My Photos', value: 'photos' },
            { label: 'AajuBaaju My Album', value: 'album' }
        ]
    }

    handlQualification = () => {
        const userId = authservice.getCurrentUserid()
        axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/user/get-content-approval-status/${userId}`)
            .then((response) => {
                const pages = response.data.approvalForMyPages
                this.setState({ myPages: pages })

                const videos = response.data.approvalForMyVideos
                this.setState({ myVideos: videos })

                const clips = response.data.approvalForMyClips
                this.setState({ myClips: clips })

                const reviews = response.data.approvalForMyReviews
                this.setState({ myReviews: reviews })

                const photos = response.data.approvalForMyPhotos
                this.setState({ myPhotos: photos })

                const album = response.data.approvalForMyAlbum
                this.setState({ myAlbum: album })
            })
            .catch((error) => {
                console.error(error);
            });
    };

    handleListingType = (selectedListingType) => {
        if (selectedListingType.disabled) {
            return; // Exit early if the selected option is disabled
        }
        this.setState({ selectedListing: selectedListingType, package: '' }, () => {
            this.resetForm();
        });
    }

    setPackage = (event) => {
        const selectedPackage = event.target.value;
        this.setState({ package: '' }, () => {
            this.setState({ package: selectedPackage })
            this.resetForm();
        })
    }

    resetForm = () => {
        this.setState({
            business_name: '',
            thumbnail_description: '',
            specialized_in: '',
            mypages_content: '',
            myvideos_content: '',
            landmark: '',
            contact_person: '',
            contact_number: '',
            email_address: '',
            website: '',
            Category: '',
            subCategory: '',
            status: "",
            selectedCategory: [],
            selectedSubCategory: [],
            selectedNeighbourhood: [],
            selectedLocality: [],
            selectedCity: [],
            selectedState: [],
            selectedAddSubCategory: "",
            experience: '',
            salary: '',
            date: null,
            time: null,
            companyName: "",
            paragraph: '',
            thumbnail_image: null,
            address_text: '',
            thumbnail_image_url: null,
            thumbnail_image_name: '',
            elements: [],
            dateAndTimeElements: [{ date: "", fromTime: "", id: 1, toTime: "", type1: "date", type2: "fromTime", type3: "toTime" }],
        }, () => {
            if (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') {
                const elements = [];
                elements.push({ type: 'video', value: '', file: null, fileURL: null, tagLine: '', id: 0 })
                elements.push({ type: 'textarea', value: '', id: 1 })
                elements.push({ type: 'url', value: '', id: 2 })
                this.setState({ elements })
            }

            if (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'reviews' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album') {
                for (let i = 0; i < this.state.allCategories.length; i++) {
                    const el = this.state.allCategories[i];
                    if (this.state.selectedListing.value === 'news' && el.category === 'Aajubaaju My Pages') {
                        this.setState({ selectedCategory: [el.categoryId] })
                        break;
                    }
                    if (this.state.selectedListing.value === 'article' && el.category === 'Aajubaaju My Videos') {
                        this.setState({ selectedCategory: [el.categoryId] })
                        break;
                    }
                    if (this.state.selectedListing.value === 'clips' && el.category === 'Aajubaaju My Clips') {
                        this.setState({ selectedCategory: [el.categoryId] })
                        break;
                    }
                    if (this.state.selectedListing.value === 'reviews' && el.category === 'Aajubaaju My Reviews') {
                        this.setState({ selectedCategory: [el.categoryId] })
                        break;
                    }
                    if (this.state.selectedListing.value === 'photos' && el.category === 'Aajubaaju My Photos') {
                        this.setState({ selectedCategory: [el.categoryId] })
                        break;
                    }
                    if (this.state.selectedListing.value === 'album' && el.category === 'Aajubaaju My Album') {
                        this.setState({ selectedCategory: [el.categoryId] })
                        break;
                    }
                }
            }
        })
    }

    setEditorData = (editorData) => {
        if (editorData) {
            let desc = JSON.parse(editorData)
            const contentState = convertFromRaw(desc);
            this.setState({ editorState: EditorState.createWithContent(contentState) })
            this.setState({ mypages_content: editorData })
            this.setState({ myvideos_content: editorData })
        }
    }

    onEditorStateChange = (editorState) => {
        this.state.errors.description = ''
        this.setState({ editorState });
        this.setState({ mypages_content: JSON.stringify(convertToRaw(editorState.getCurrentContent())) })
        this.setState({ myvideos_content: JSON.stringify(convertToRaw(editorState.getCurrentContent())) })
        //console.log(JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())))
        this.state.editorData = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    }

    getPlainTextFromEditor = () => {
        const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;
        const mappedBlocks = blocks.map(
            block => (!block.text.trim() && "\n") || block.text
        );

        let newText = "";
        for (let i = 0; i < mappedBlocks.length; i++) {
            const block = mappedBlocks[i];

            if (i === mappedBlocks.length - 1) {
                newText += block; // handle last block
            } else {
                if (block !== "\n") newText += block; //ignore all \n

                // otherwise we join with \n, except if the block is already a \n
                // if (block === "\n") newText += block;
                // else newText += block + "\n";
            }
        }
        return newText;
    }

    editorImageUpload = (selectedImageFile) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", selectedImageFile)
            axios.post(process.env.REACT_APP_BACKEND_URL + "/hpmgr/listing/upload-image", formData).then(response => {
                console.log(response)
                if (response.data !== 'failed') {
                    resolve({ data: { link: process.env.REACT_APP_IMAGES_URL + response.data } });
                } else {
                    reject({ data: null })
                }
            });
        })
    }

    changeTime = (time, timeString) => {
        this.state.openingHours.push(timeString)
    }

    elementId = 0;
    addElements = (element, index = null) => {
        let newElementId = this.elementId + 1;

        if (index === null) {
            // If no index is specified, add the element to the end of the list
            index = this.state.elements.length;
        } else {
            // If an index is specified, increment the IDs of all elements after that index
            for (let i = index; i < this.state.elements.length; i++) {
                this.state.elements[i].id = this.state.elements[i].id + 1;
            }
            newElementId = this.state.elements[index].id + 1;
        }

        const elements = [...this.state.elements];
        if (element.type === "text" || element.type === "textarea" || element.type === "url") {
            elements.splice(index, 0, { type: element.type, value: '', id: newElementId });
        } else {
            elements.splice(index, 0, { type: element.type, value: '', file: null, fileURL: null, tagLine: '', id: newElementId });
        }

        this.setState({ elements }, () => {
            const insertedItem = 'article_news_data' + (index + 2);
            //this.scrollElementToCenter(insertedItem);
        });
    };


    removeElement = (data) => {
        const elements = [...this.state.elements]
        const deletedEl = elements.splice(data.index, 1)[0]
        if (deletedEl.contentId)
            this.deletedElements.push(deletedEl.contentId)
        let errors = this.state.errors;
        switch (deletedEl.type) {
            case 'text':
                if (errors['subHeading' + data.index])
                    errors['subHeading' + data.index] = ''
                break;
            case 'textarea':
                if (errors['paragraph' + data.index])
                    errors['paragraph' + data.index] = ''
                break;
            default:
                break;
        }
        this.setState({ elements: elements, errors })
        this.closeConfirmationPopup()
    }

    removeConfirmation = (index, itemType) => {
        const type = this.state.elements[index].type
        if (((type === "text" || type === "textarea") && this.state.elements[index].value === '') || ((type === "image" || type === "video") && this.state.elements[index].fileURL === null))
            this.removeElement({ index: index })
        else {
            this.currentElement = {
                index: index,
                name: `Delete ${this.state.selectedListing.label} "${itemType}"`,
            }
            this.setState({ showConfirmationPopup: true })
        }
    }

    closeConfirmationPopup = () => {
        this.currentElement = null
        this.setState({ showConfirmationPopup: false })
    }

    writeContent = (event, index, type) => {
        const elements = [...this.state.elements]
        if (type === "text" || type === "textarea" || type === "url") {
            elements[index] = { ...elements[index], value: event.target.value };
        } else {
            elements[index] = { ...elements[index], tagLine: event.target.value };
        }
        this.setState({ elements: elements })
    }

    selectMedia = (event, index) => {

        if (event.target.files && event.target.files[0]) {
            const elements = [...this.state.elements]
            elements[index] = {
                ...elements[index],
                file: event.target.files[0],
                fileURL: window.URL.createObjectURL(event.target.files[0]),
                value: event.target.files[0].name
            };
            this.setState({ elements: elements })
        }
    }

    uploadThumbnail = () => {
        this.setState({ thumbnailImageUploading: true })
        const errors = this.state.errors;
        const listingType = this.state.selectedListing.value;
        const data = new FormData();
        data.append("image", this.state.thumbnail_image);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/upload-image?type=thumbnail&listingType=${listingType}`, data).then(resp => {
            if (resp.data !== 'failed') {
                errors.thumbnailImageSizeError = ''
                this.setState({
                    thumbnailImageUploading: false,
                    thumbnail_image_url: process.env.REACT_APP_IMAGES_URL + resp.data,
                    thumbnail_image_name: resp.data,
                    thumbnail_image: null,
                    errors
                })
            } else {
                errors.thumbnailImageSizeError = 'Failed to upload'
                this.setState({
                    thumbnailImageUploading: false,
                    errors
                })
            }
        }).catch(error => {
            if (error.response && error.response.data)
                notifyUser('Thumbnail Image', error.response.data, type.DANGER)
            console.log(error)
            errors.thumbnailImageSizeError = 'Something went wrong Please try again'
            this.setState({ thumbnailImageUploading: false, errors })
        })
    }

    /**
     * upload insert item in article or new type
     * @param {number} index 
     *      index of the inserted item 
     * @param {string} type
     *      type of inserted item 
     * @author sunny
     */

    uploadMedia = (index, type) => {
        const errors = this.state.errors;
        const selectedMediaFile = this.state.elements[index].file;

        if (selectedMediaFile && selectedMediaFile.size < MAX_FILE_SIZE) {
            let elementStatus = this.state.elementStatus;
            elementStatus[index] = true;
            this.setState({ elementStatus: elementStatus });

            const formData = new FormData();
            formData.append("mediaFile", selectedMediaFile);

            if (this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'photos') {
                axios.post(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/upload-article-media`, formData,
                    {
                        params: {
                            elementId: index
                        }
                    }).then(response => {
                        if (response.data !== 'failed') {
                            errors.uploadError = '';
                            let elements = [...this.state.elements];
                            elements[index].value = response.data[index];
                            elements[index].fileURL = process.env.REACT_APP_IMAGES_URL + response.data[index];
                            elements[index].file = null;
                            elementStatus[index] = false;
                            this.setState({ elementStatus: elementStatus, elements: elements, errors });
                        } else {
                            errors.uploadError = 'Failed to upload';
                            this.setState({ errors });
                        }
                    }).catch(error => {
                        if (error.response)
                            notifyUser(`Upload ${type}`, error.response.data, notificationType.WARNING);
                        else
                            notifyUser(`Upload ${type}`, 'Oops..! something went wrong. Try again.', notificationType.WARNING);
                        elementStatus[index] = false;
                        this.setState({ elementStatus: elementStatus });
                    });
            }

            const video = document.createElement('video');
            video.src = URL.createObjectURL(selectedMediaFile);

            video.addEventListener('loadedmetadata', () => {
                const aspectRatio = video.videoWidth / video.videoHeight;

                if (this.state.selectedListing.value === 'clips' && video.duration < 14 || this.state.selectedListing.value === 'clips' && video.duration > 125 || this.state.selectedListing.value === 'clips' && Math.abs(aspectRatio - (9 / 16)) > 0.01) {
                    if (Math.abs(aspectRatio - (9 / 16)) > 0.01) {
                        notifyUser("Clip Video", "Please upload clips of MP4 format, portrait orientation with 9:16 aspect ratio. and Clip Video duration should be minimum of 15 seconds and should not exceed 2 minutes.", notificationType.DANGER);
                        elementStatus[index] = false;
                        this.setState({ elementStatus: elementStatus });

                    } else if (video.duration < 14 || video.duration > 125) {
                        notifyUser("Clip Video", "Clip Video duration should be minimum of 15 seconds and should not exceed 2 minutes.", notificationType.DANGER);
                        elementStatus[index] = false;
                        this.setState({ elementStatus: elementStatus });
                    }
                }
                else {
                    axios.post(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/upload-article-media`, formData,
                        {
                            params: {
                                elementId: index
                            }
                        }).then(response => {
                            if (response.data !== 'failed') {
                                errors.uploadError = '';
                                let elements = [...this.state.elements];
                                elements[index].value = response.data[index];
                                elements[index].fileURL = process.env.REACT_APP_IMAGES_URL + response.data[index];
                                elements[index].file = null;
                                elementStatus[index] = false;
                                this.setState({ elementStatus: elementStatus, elements: elements, errors });
                            } else {
                                errors.uploadError = 'Failed to upload';
                                this.setState({ errors });
                            }
                        }).catch(error => {
                            if (error.response)
                                notifyUser(`Upload ${type}`, error.response.data, notificationType.WARNING);
                            else
                                notifyUser(`Upload ${type}`, 'Oops..! something went wrong. Try again.', notificationType.WARNING);
                            elementStatus[index] = false;
                            this.setState({ elementStatus: elementStatus });
                        });
                }

                // Clean up object URL to prevent memory leaks
                URL.revokeObjectURL(video.src);
            });
        } else {
            if (!selectedMediaFile)
                console.log("Please Select Image");
            else
                notifyUser(`${type} size too large`, `${type} ${MAX_FILE_SIZE_ERROR}`, notificationType.WARNING);
        }
    }

    onImageChange = (event) => {

        this.setState({ thumbnail_image_url: null })
        this.setState({ thumbnail_image: event })
        if (event) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ thumbnail_image_url: e.target.result });
            };
            reader.readAsDataURL(event);

            let errors = this.state.errors;
            if (event.size > MAX_FILE_SIZE)
                errors.thumbnailImageSizeError = MAX_FILE_SIZE_ERROR
            else
                errors.thumbnailImageSizeError = ''
            this.setState({ errors: errors })
        }
    }

    getBusinessAddress = (states, cities, localities, neighbourhoods) => {
        let addresses = [...this.props.post_data.businessAddress]
        if (addresses.length > 0) {
            addresses.forEach(e => {
                let addrOptionIndex = -1, localityIndex = -1, cityIndex = -1, stateIndex = -1
                switch (e.placeType) {
                    case 'Neighbourhoods':
                        addrOptionIndex = neighbourhoods.findIndex(nigh => {
                            return nigh === e.place;
                        })
                        if (addrOptionIndex === -1) {
                            for (let index = 0; index < this.state.fullAddress.length; index++) {
                                const addr = this.state.fullAddress[index];
                                if (addr.neighbourhoodId === e.place) {
                                    localityIndex = localities.findIndex(loc => {
                                        return loc === addr.localityId;
                                    })
                                    if (localityIndex !== -1) {
                                        e.placeType = 'Localities';
                                        e.place = localities[localityIndex];
                                        break;
                                    } else {
                                        cityIndex = cities.findIndex(city => {
                                            return city === addr.cityId;
                                        })
                                        if (cityIndex !== -1) {
                                            e.placeType = 'Cities';
                                            e.place = cities[cityIndex];
                                            break;
                                        } else {
                                            stateIndex = states.findIndex(state => {
                                                return state === addr.stateId;
                                            })
                                            if (stateIndex !== -1) {
                                                e.placeType = 'States';
                                                e.place = states[stateIndex];
                                                break;
                                            } else {
                                                e.placeType = 'Country';
                                                e.place = 1;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        break;

                    case 'Localities':
                        addrOptionIndex = localities.findIndex(loc => {
                            return loc === e.place;
                        })
                        if (addrOptionIndex === -1) {
                            for (let index = 0; index < this.state.fullAddress.length; index++) {
                                const addr = this.state.fullAddress[index];
                                if (addr.localityId === e.place) {
                                    cityIndex = cities.findIndex(city => {
                                        return city === addr.cityId;
                                    })
                                    if (cityIndex !== -1) {
                                        e.placeType = 'Cities';
                                        e.place = cities[cityIndex];
                                        break;
                                    } else {
                                        stateIndex = states.findIndex(state => {
                                            return state === addr.stateId;
                                        })
                                        if (stateIndex !== -1) {
                                            e.placeType = 'States';
                                            e.place = states[stateIndex];
                                            break;
                                        } else {
                                            e.placeType = 'Country';
                                            e.place = 1;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        break;

                    case 'Cities':
                        addrOptionIndex = cities.findIndex(city => {
                            return city === e.place;
                        })
                        if (addrOptionIndex === -1) {
                            for (let index = 0; index < this.state.fullAddress.length; index++) {
                                const addr = this.state.fullAddress[index];
                                if (addr.cityId === e.place) {
                                    stateIndex = states.findIndex(state => {
                                        return state === addr.stateId;
                                    })
                                    if (stateIndex !== -1) {
                                        e.placeType = 'States';
                                        e.place = states[stateIndex];
                                        break;
                                    } else {
                                        e.placeType = 'Country';
                                        e.place = 1;
                                        break;
                                    }
                                }
                            }
                        }
                        break;

                    case 'States':
                        addrOptionIndex = states.findIndex(state => {
                            return state === e.place;
                        })
                        if (addrOptionIndex === -1) {
                            e.placeType = 'Country';
                            e.place = 1;
                        }
                        break;

                    default:
                        break;
                }
            })
        }
        return addresses;
    }

    uploadFileHandler = (basicAddress) => {
        let states = []
        if (this.selectedState.length)
            states = this.selectedState.map(data => { return data.value })
        let cities = []
        if (this.selectedCity.length)
            cities = this.selectedCity.map(data => { return data.value })
        let localities = []
        if (this.selectedLocality.length)
            localities = this.selectedLocality.map(data => { return data.value })
        let neighbourhoods = []
        if (this.selectedNeighbourhood.length)
            neighbourhoods = this.selectedNeighbourhood.map(data => { return data.value })
        let categories = []
        if (this.selectedCategories.length)
            categories = this.selectedCategories.map(data => { return data.value })
        let subCategories = []
        if (this.selectedSubCategories.length)
            subCategories = this.selectedSubCategories.map(data => { return data.value })
        let business_address = [];
        if (this.state.package === 'basic') {
            if (this.state.address_text !== '') {
                business_address = basicAddress;
            }
        } else if (this.props.post_data.businessAddress && this.props.post_data.businessAddress.length) {
            business_address = this.getBusinessAddress(states, cities, localities, neighbourhoods);
        }

        let eventDateTime = [...this.state.dateAndTimeElements];
        if (eventDateTime && eventDateTime.length > 0) {
            eventDateTime = eventDateTime.sort((a, b) => {
                const aTimeStamp = moment(a.date + " " + a.fromTime, 'DD/MM/YYYY hh:mm a')
                const bTimeStamp = moment(b.date + " " + b.fromTime, 'DD/MM/YYYY hh:mm a')
                const re = aTimeStamp > bTimeStamp ? 1 : aTimeStamp < bTimeStamp ? -1 : 0;
                return re
            })
        }

        const bodyData = {
            hpId: this.props.post_data.hpId,
            categories: categories,
            subCategories: subCategories,
            country: "India",
            states: states,
            cities: cities,
            localities: localities,
            neighbourhoods: neighbourhoods,
            businessName: this.state.business_name,
            thumbnailDescription: this.state.thumbnail_description,
            specializedIn: this.state.specialized_in,
            mypages_content: this.state.mypages_content,
            myvideos_content: this.state.myvideos_content,
            listingType: this.state.selectedListing.value,
            businessPackage: this.state.package,
            businessAddress: business_address,
            contactPerson: this.state.contact_person,
            contactNumber: this.state.contact_number,
            emailAddress: this.state.email_address,
            website: this.state.website,
            elements: this.state.elements,
            featuredImage: this.props.post_data.featuredImage,
            experience: this.state.experience,
            salary: this.state.salary,
            dateAndTime: this.state.date,
            companyName: this.state.companyName,
            paragraph: this.state.paragraph,
            thumbnailImage: this.state.thumbnail_image_name,
            userId: authservice.getCurrentUserid(),
            order: this.props.post_data.order,
            index: this.props.post_data.index,
            mondayOpen: this.props.post_data.mondayOpen,
            mondayClose: this.props.post_data.mondayClose,
            tuesdayOpen: this.props.post_data.tuesdayOpen,
            tuesdayClose: this.props.post_data.tuesdayClose,
            wednesdayOpen: this.props.post_data.wednesdayOpen,
            wednesdayClose: this.props.post_data.wednesdayClose,
            thursdayOpen: this.props.post_data.thursdayOpen,
            thursdayClose: this.props.post_data.thursdayClose,
            fridayOpen: this.props.post_data.fridayOpen,
            fridayClose: this.props.post_data.fridayClose,
            saturdayOpen: this.props.post_data.saturdayOpen,
            saturdayClose: this.props.post_data.saturdayClose,
            sundayOpen: this.props.post_data.sundayOpen,
            sundayClose: this.props.post_data.sundayClose,
            companyDescription: this.props.post_data.companyDescription,
            organizerAddress: this.props.post_data.organizerAddress,
            organizerPerson: this.props.post_data.organizerPerson,
            organizerEmail: this.props.post_data.organizerEmail,
            organizerLandmark: this.props.post_data.organizerLandmark,
            organizerPhone: this.props.post_data.organizerPhone,
            organizerWebsite: this.props.post_data.organizerWebsite,
            organizerName: this.props.post_data.organizerName,
            dateAndTimeElements: eventDateTime,
            addressOptions: this.addressOptions,
            dateAndTimeElementsADV: this.props.post_data.dateAndTimeElementsADV,
            sliderImages: this.props.post_data.sliderImages,
            // galleryImages: this.props.post_data.galleryImages,
            listingStatus: this.props.post_data.listingStatus,
            orders: this.props.post_data.orders,
            deletedElements: this.deletedElements,
            deletedDateTimeElements: this.deletedDateAndTimeElements,
        }

        if (this.state.selectedListing !== '') {
            this.props.allCategories(this.state.allCategories);
            this.props.fullAddress(this.state.fullAddress);
            this.props.sendData(bodyData);
            //selected address values set in redux
            if ((this.state.selectedListing.value === 'news') || (this.state.selectedListing.value === 'reviews') || (this.state.selectedListing.value === 'photos') || (this.state.selectedListing.value === 'album') || (this.state.selectedListing.value === 'article' || this.state.package === 'basic') || (this.state.selectedListing.value === 'clips' || this.state.package === 'basic')) {
                let invalidDetails = false;
                if ((this.state.selectedListing.value === 'event' || this.state.selectedListing.value === 'job')) {
                    for (let i = 0; i < this.state.dateAndTimeElements.length; i++) {
                        const dateTime = this.state.dateAndTimeElements[i];
                        if ((dateTime.date === '' || dateTime.toTime === '')) {
                            invalidDetails = true;
                            break;
                        }
                    }
                }
                if (!invalidDetails)
                    this.props.history.push('/listing-basic');
                else
                    notifyUser("Date", "Please Provide Date And Time", "info");
            } else {
                this.props.setAddress(this.addressOptions);
                if (this.state.package === 'advanced') {
                    if (this.state.readOnly) {
                        this.props.history.push(`/admin-panel/manage-posts/post/${this.state.hpId}/content/advanced`);
                    } else {
                        if (this.state.hpId)
                            this.props.history.push(`/update-post/content/advanced/${this.state.hpId}`)
                        else
                            this.props.history.push('/listing-advanced');
                    }
                } else if (this.state.package === 'premium') {
                    if (this.state.readOnly) {
                        this.props.history.push(`/admin-panel/manage-posts/post/${this.state.hpId}/content/premium`);
                    } else {
                        if (this.state.hpId)
                            this.props.history.push(`/update-post/content/premium/${this.state.hpId}`)
                        else
                            this.props.history.push('/listing-premium');
                    }
                } else {
                    //alert('Save the all addresses which are selected in location');
                    notifyUser("Set Address", "Save the all addresses which are selected in location", "info");
                }
            }
        }
    }

    saveAsDraft = (action, basicAddress) => {

        if (this.validateRequiredFields()) {
            return;
        }
        Loader.runSpinner()
        let states = []
        if (this.selectedState.length)
            states = this.selectedState.map(data => { return data.value })
        let cities = []
        if (this.selectedCity.length)
            cities = this.selectedCity.map(data => { return data.value })
        let localities = []
        if (this.selectedLocality.length)
            localities = this.selectedLocality.map(data => { return data.value })
        let neighbourhoods = []
        if (this.selectedNeighbourhood.length)
            neighbourhoods = this.selectedNeighbourhood.map(data => { return data.value })
        let categories = []
        if (this.selectedCategories.length)
            categories = this.selectedCategories.map(data => { return data.value })
        let subCategories = []
        if (this.selectedSubCategories.length)
            subCategories = this.selectedSubCategories.map(data => { return data.value })
        let business_address = [];
        if (this.state.package === 'basic') {
            if (this.state.address_text !== '') {
                business_address = basicAddress;
            }
        } else if (this.props.post_data.businessAddress && this.props.post_data.businessAddress.length) {
            business_address = this.getBusinessAddress(states, cities, localities, neighbourhoods);
        }
        let eventDateTime = [...this.state.dateAndTimeElements];
        if (eventDateTime && eventDateTime.length > 0) {
            eventDateTime = eventDateTime.sort((a, b) => {
                const aTimeStamp = moment(a.date + " " + a.fromTime, 'DD/MM/YYYY hh:mm a')
                const bTimeStamp = moment(b.date + " " + b.fromTime, 'DD/MM/YYYY hh:mm a')
                const re = aTimeStamp > bTimeStamp ? 1 : aTimeStamp < bTimeStamp ? -1 : 0;
                return re
            })
        }
        Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_GEO_API_KEY}&libraries=places&callback=initAutocomplete`);
        Geocode.enableDebug();
        Geocode.fromAddress(this.state.address_text + ',India').then(response => {
            const { lat, lng } = response.results[0].geometry.location;
            if (lat !== '') {
                const addr = this.props.post_data.businessAddress
                const business_address = [{
                    id: (addr && addr.length) ? addr[0].id : null,
                    addressIndex: 0,
                    address: this.state.address_text,
                    landmark: this.state.landmark,
                    lattitude: lat,
                    longitude: lng
                }];
                const listingData = {
                    hpId: this.props.post_data.hpId,
                    categories: categories,
                    subCategories: subCategories,
                    country: "India",
                    states: states,
                    cities: cities,
                    localities: localities,
                    neighbourhoods: neighbourhoods,
                    businessName: this.state.business_name,
                    thumbnailDescription: this.state.thumbnail_description,
                    specializedIn: this.state.specialized_in,
                    mypages_content: this.state.mypages_content,
                    myvideos_content: this.state.myvideos_content,
                    listingType: this.state.selectedListing.value,
                    businessPackage: this.state.package,
                    businessAddress: business_address,
                    contactPerson: this.state.contact_person,
                    contactNumber: this.state.contact_number,
                    emailAddress: this.state.email_address,
                    website: this.state.website,
                    elements: this.state.elements,
                    featuredImage: this.props.post_data.featuredImage,
                    experience: this.state.experience,
                    salary: this.state.salary,
                    dateAndTime: this.state.date,
                    companyName: this.state.companyName,
                    paragraph: this.state.paragraph,
                    thumbnailImage: this.state.thumbnail_image_name,
                    userId: authservice.getCurrentUserid(),
                    order: this.props.post_data.order,
                    index: this.props.post_data.index,
                    mondayOpen: this.props.post_data.mondayOpen,
                    mondayClose: this.props.post_data.mondayClose,
                    tuesdayOpen: this.props.post_data.tuesdayOpen,
                    tuesdayClose: this.props.post_data.tuesdayClose,
                    wednesdayOpen: this.props.post_data.wednesdayOpen,
                    wednesdayClose: this.props.post_data.wednesdayClose,
                    thursdayOpen: this.props.post_data.thursdayOpen,
                    thursdayClose: this.props.post_data.thursdayClose,
                    fridayOpen: this.props.post_data.fridayOpen,
                    fridayClose: this.props.post_data.fridayClose,
                    saturdayOpen: this.props.post_data.saturdayOpen,
                    saturdayClose: this.props.post_data.saturdayClose,
                    sundayOpen: this.props.post_data.sundayOpen,
                    sundayClose: this.props.post_data.sundayClose,
                    companyDescription: this.props.post_data.companyDescription,
                    organizerAddress: this.props.post_data.organizerAddress,
                    organizerPerson: this.props.post_data.organizerPerson,
                    organizerEmail: this.props.post_data.organizerEmail,
                    organizerLandmark: this.props.post_data.organizerLandmark,
                    organizerPhone: this.props.post_data.organizerPhone,
                    organizerWebsite: this.props.post_data.organizerWebsite,
                    organizerName: this.props.post_data.organizerName,
                    dateAndTimeElements: eventDateTime,
                    addressOptions: this.addressOptions,
                    dateAndTimeElementsADV: this.props.post_data.dateAndTimeElementsADV,
                    sliderImages: this.props.post_data.sliderImages,
                    // galleryImages: this.props.post_data.galleryImages,
                    listingStatus: this.props.post_data.listingStatus,
                    orders: this.props.post_data.orders,
                    deletedElements: this.deletedElements,
                    deletedDateTimeElements: this.deletedDateAndTimeElements,
                }
                console.log(listingData)
                if (!listingData.hpId)
                    listingData.listingStatus = "draft";

                axios.post(process.env.REACT_APP_BACKEND_URL + "/hpmgr/listing/save", listingData).then(response => {
                    if (response.data)
                        console.log(listingData)
                    if (action === 'draft') {
                        listingData.hpId = response.data.id;
                        this.props.sendData(listingData);
                        notifyUser("Listing", `Listing : Your ${this.state.selectedListing.value === 'article' ? 'My Video' : this.state.selectedListing.value === 'clips' ? 'My Clip' : this.state.selectedListing.value === 'news' ? 'My Page' : this.state.selectedListing.value} is Saved as a Draft. Please Visit Manage Posts Section to Edit and Submit for Approval"`, type.SUCCESS);
                        listingData.listingStatus = "draft";
                        //this.props.history.push('/manageposts')
                        // this.props.history.push('/order/' + response.data.id, {
                        //     payfor: 'listing',
                        //     package: listingData.businessPackage,
                        //     orderSummary: response.data,
                        // })
                    }
                })
                Loader.stopSpinner();
            } else {
                Loader.stopSpinner();
                notifyUser("Set Address", this.state.address_text + " address is Invalid", notificationType.WARNING);
            }
        }, error => {
            Loader.stopSpinner();
            notifyUser("Set Address", this.state.address_text + " address is Invalid", notificationType.WARNING);
            this.scrollElementToCenter('addressInfo')
        })
    }

    savePostSettings = (e) => {
        e.preventDefault();
        if (this.validateRequiredFields()) {
            return;
        }
        Loader.runSpinner();

        let states = []
        if (this.selectedState.length)
            states = this.selectedState.map(data => { return data.value })
        let cities = []
        if (this.selectedCity.length)
            cities = this.selectedCity.map(data => { return data.value })
        let localities = []
        if (this.selectedLocality.length)
            localities = this.selectedLocality.map(data => { return data.value })
        let neighbourhoods = []
        if (this.selectedNeighbourhood.length)
            neighbourhoods = this.selectedNeighbourhood.map(data => { return data.value })
        let categories = []
        if (this.selectedCategories.length)
            categories = this.selectedCategories.map(data => { return data.value })
        let subCategories = []
        if (this.selectedSubCategories.length)
            subCategories = this.selectedSubCategories.map(data => { return data.value })

        const listingData = {
            hpId: this.props.post_data.hpId,
            listingType: this.state.selectedListing.value,
            businessPackage: this.state.package,
            categories: categories,
            subCategories: subCategories,
            country: "India",
            states: states,
            cities: cities,
            localities: localities,
            neighbourhoods: neighbourhoods,
            businessAddress: this.getBusinessAddress(states, cities, localities, neighbourhoods),
            thumbnailImage: this.state.thumbnail_image_name,
            thumbnailDescription: this.state.thumbnail_description,
            dateAndTimeElements: this.props.post_data.dateAndTimeElementsADV,
        }

        this.setState({ listing_save: true });
        axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/user/update-post-settings", listingData).then(response => {
            if (response.data) {
                Loader.stopSpinner();
                notifyUser("Listing", "Settings Updated Successfully " + this.props.post_data.businessName, notificationType.SUCCESS);
                this.props.history.push('/manageposts')
            } else {
                Loader.stopSpinner();
                notifyUser("Listing", "Listing is Not Updated " + listingData.businessName, type.DANGER);
            }
        }).catch(error => {
            Loader.stopSpinner();
            notifyUser("Listing", "Listing is Not Updated " + this.props.post_data.businessName, type.DANGER);
        })
    }

    resetErrors = () => {
        let errors = this.state.errors
        for (const key in errors) {
            errors[key] = '';
        }
        this.setState({ errors })
    }

    getExistingPost = (id) => {
        Loader.runSpinner();
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/user/getpost/' + id)
            .then(response => {
                this.props.sendData(response.data);
                this.dataSetup(response.data);
                Loader.stopSpinner();
            })
    }

    componentDidUpdate() {
        if (!this.props.is_login) {
            this.props.sendData([])
            this.props.history.push('/login/addpost')
        }
    }

    componentDidMount() {
        this.handlQualification()
        const selectedDateType = localStorage.getItem('selectedDateType');
        if (selectedDateType) {
            this.setState({ selectedDateType });
        }
        window.scrollTo(0, 0)
        this.setState({ listingTypeOptions: this.listingType() })//listing options load while component loading
        const userId = authservice.getCurrentUserid()

        Promise.all([
            axios.get(process.env.REACT_APP_BACKEND_URL + '/hpmgr/listing/getallstates'),
            axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/v2-getallcategories/${userId}`)
        ]).then(([getFullAddress, getAllCategories]) => { // use arrow function to avoid loosing context, BTW you don't need to use axios.spread with ES2015 destructuring
            this.setState({ fullAddress: getFullAddress.data, allCategories: getAllCategories.data });
            this.props.fullAddress(getFullAddress.data)
            this.props.allCategories(getAllCategories.data)
            Loader.stopSpinner()
            if (this.props.match.params.id && !this.props.post_data.hpId) {
                this.getExistingPost(this.props.match.params.id);
            } else if (this.props.post_data.listingType) {
                this.dataSetup(this.props.post_data);
            }
        }).catch(error => {
            Loader.stopSpinner()
            console.log(error, 'getting data failed');
        });

        if (this.props.match.path === '/admin-panel/manage-posts/post/:id') {
            this.setState({ readOnly: true });
        }
    }

    filterDataSetup = () => {
        if (this.props.post_data.categories !== undefined) {
            this.setState({ selectedCategory: this.props.post_data.categories })
        }
        if (this.props.post_data.subCategories !== undefined) {
            this.setState({ selectedSubCategory: this.props.post_data.subCategories })
        }

        if (this.props.post_data.states) {
            this.setState({ selectedState: this.props.post_data.states })
        }
        if (this.props.post_data.cities) {
            this.setState({ selectedCity: this.props.post_data.cities })
        }
        if (this.props.post_data.localities !== undefined) {
            this.setState({ selectedLocality: this.props.post_data.localities })
        }
        if (this.props.post_data.neighbourhoods !== undefined) {
            this.setState({ selectedNeighbourhood: this.props.post_data.neighbourhoods })
        }
    }

    dataSetup = (post_data) => {
        this.filterDataSetup();

        if (post_data.hpId) {
            this.setState({ hpId: post_data.hpId })
        }

        if (post_data.contactPerson) {
            this.setState({ contact_person: post_data.contactPerson })
        }
        if (post_data.contactNumber) {
            this.setState({ contact_number: post_data.contactNumber })
        }

        if (this.state.dateAndTimeElements.length === 0) {
            const dateAndTimeElements = [...this.state.dateAndTimeElements];
            this.dateAndTimeElementId++
            dateAndTimeElements[this.state.dateAndTimeElements.length] = { type1: 'date', type2: 'fromTime', type3: 'toTime', date: '', fromTime: '', toTime: '', id: this.dateAndTimeElementId }
            this.setState({ dateAndTimeElements: dateAndTimeElements })
        }

        if (post_data.mypages_content) {
            this.setEditorData(post_data.mypages_content);
        }

        if (post_data.myvideos_content) {
            this.setEditorData(post_data.myvideos_content);
        }

        if (post_data.businessName !== undefined) {
            this.setState({ business_name: post_data.businessName })
        }
        if (post_data.thumbnailDescription) {
            this.setState({ thumbnail_description: post_data.thumbnailDescription })
        }

        if (post_data.specializedIn !== undefined) {
            this.setState({ specialized_in: post_data.specializedIn })
        }

        if (post_data.emailAddress !== undefined) {
            this.setState({ email_address: post_data.emailAddress })
        }

        if (post_data.website !== undefined) {
            this.setState({ website: post_data.website })
        }
        if (post_data.businessPackage !== undefined) {
            this.setState({ package: post_data.businessPackage })
        }
        if (post_data.experience !== undefined) {
            this.setState({ experience: post_data.experience })
        }
        if (post_data.salary !== null) {
            this.setState({ salary: post_data.salary })
        }
        if (post_data.companyName !== undefined) {
            this.setState({ companyName: post_data.companyName })
        }
        if (post_data.dateAndTimeElements) {
            let dateAndTime = [...post_data.dateAndTimeElements];
            dateAndTime.forEach(e => {
                e.type1 = "date"
                e.type2 = "fromTime"
                e.type3 = "toTime"
            })
            this.setState({ dateAndTimeElements: dateAndTime })
        }

        if (post_data.listingType !== undefined) {
            const listFromPreview = this.listingType().findIndex(op => {
                return op.value === post_data.listingType
            })
            this.setState({ selectedListing: this.listingType()[listFromPreview] })
        }

        if (this.props.post_data.elements) {
            let elements = this.props.post_data.elements
            elements.map((element, index) => {
                if (element.type === 'image' || element.type === 'video')
                    if (!element.fileURL)
                        element.fileURL = process.env.REACT_APP_IMAGES_URL + element.value;
            })
            this.setState({ elements: elements })
        }

        if (post_data.businessAddress !== undefined && post_data.businessAddress.length > 0) {
            if (post_data.businessPackage === "basic") {
                if (post_data.businessAddress.length > 0) {
                    this.setState({ address_text: post_data.businessAddress[0].address })
                    this.setState({ landmark: post_data.businessAddress[0].landmark ? post_data.businessAddress[0].landmark : '' })
                } else {
                    this.setState({ address_text: '' })
                    this.setState({ landmark: '' })
                }
            }
        }

        if (this.props.set_address && this.props.set_address.length > 0) {
            this.addressOptions = this.props.set_address
        } else if (post_data.set_address && post_data.set_address.length > 0) {
            this.addressOptions = post_data.set_address
            this.props.setAddress(post_data.set_address);
        }
        if (post_data.thumbnailImage) {
            this.setState({
                thumbnail_image_url: process.env.REACT_APP_IMAGES_URL + post_data.thumbnailImage,
                thumbnail_image_name: post_data.thumbnailImage,
                thumbnail_image: null
            })
        } else {
            let errors = this.state.errors;
            errors.thumbnailImageSizeError = 'Unable to retrieve thumbnail image'
            this.setState({ errors })
        }

        if (post_data.deletedElements)
            this.deletedElements = [...post_data.deletedElements]
        if (post_data.deletedDateAndTimeElements)
            post_data.deletedDateAndTimeElements.forEach(e => {
                if (e.interviewId)
                    this.deletedDateAndTimeElements.push(e.interviewId)
            })
    }

    _handleNewCategory = (data) => {
        this.setState({ allCategories: [...this.state.allCategories, data] })
    }

    _handleCategory = (selectedItems) => {
        if (selectedItems.length)
            this.selectedCategories = selectedItems;
        else
            this.selectedCategories = [];
    }
    _handleSubCategory = (selectedItems) => {
        if (selectedItems.length)
            this.selectedSubCategories = selectedItems;
        else
            this.selectedSubCategories = [];
    }

    _handleNewLocation = (data) => {
        this.setState({ fullAddress: [...this.state.fullAddress, data] })
    }

    _handleState = (selectedStates) => {
        if (selectedStates.length)
            this.selectedState = selectedStates;
        else
            this.selectedState = [];
    }
    _handleCity = (e) => {
        if (e.length)
            this.selectedCity = e;
        else
            this.selectedCity = [];
    }
    _handleLocality = (e) => {
        if (e.length)
            this.selectedLocality = e;
        else
            this.selectedLocality = [];
    }
    _handleNeighbourhood = (e) => {
        if (e.length)
            this.selectedNeighbourhood = e
        else
            this.selectedNeighbourhood = [];
    }

    setAddressOptions = (options) => {
        this.addressOptions = options
    }

    setCategoryValidationMethod = (m) => {
        this.categoryValidation = m;
    }

    setSubCategoryValidationMethod = (m) => {
        this.subCategoryValidation = m;
    }

    setStateValidationMethod = (m) => {
        this.stateValidation = m;
    }

    setCityValidationMethod = (m) => {
        this.cityValidation = m;
    }

    setLocalityValidationMethod = (m) => {
        this.localityValidation = m;
    }

    setNeighbourhoodValidationMethod = (m) => {
        this.neighbourhoodValidation = m;
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        if (value) {
            switch (name) {
                case 'required_name': {
                    errors.required_name = personName.test(value) && value.length > 2
                        ? ''
                        : 'Required Name must contain at least one alphabet and minimum three character'
                    break;
                }
                case 'company_name': {
                    errors.company_name = personName.test(value) && value.length > 2
                        ? ''
                        : 'Company Name must contain at least one alphabet and minimum three character'
                    break;
                }
                case 'email_address': {
                    errors.email_address =
                        validEmailRegex.test(value)
                            ? ''
                            : 'Email is not valid!';
                    break;
                }
                case 'contact_person': {
                    errors.contact_person = personName.test(value) && value.length > 2
                        ? ''
                        : 'Name must contain at least one alphabet and minimum three character'
                    break;
                }
                case 'contact_number': {
                    errors.contact_number =
                        phonenumberRegex.test(value)
                            ? ''
                            : 'Enter 10 digit mobile number or landline with the STD code'
                    break;
                }
                case 'website': {
                    let fulWebsite = 'www.' + value;
                    errors.website = webSiteDomain.test(fulWebsite) ? '' : 'Website is not valid!';
                    break;
                }
                case 'address_info': {
                    errors.address_info = address.test(value) && zipCode.test(value)
                        ? ''
                        : 'Enter as House/Building, Street Name, Neighbourhood, Locality, City - Pincode'
                    break;
                }
                case 'thumbnailDescription':
                    errors.thumbnailDescription = personName.test(value) && value.length > 2
                        ? '' : 'Thumbnail Description must contain at least one alphabet and minimum three character'
                    break;
                case 'specializedIn':
                    errors.specializedIn = personName.test(value) && value.length > 2
                        ? '' : 'Field must contain at least one alphabet and minimum three character';
                    break;
                case 'subHeading':
                case 'paragraph':
                    const id = event.target.id
                    errors[id] = personName.test(value) && value.length > 2
                        ? '' : 'Field must contain at least one alphabet and minimum three character';
                    break;
                default:
                    break;
            }
        } else {
            errors[name] = '';
        }
        this.setState({ errors })
    }

    scrollElementToCenter = (elementId) => {
        Loader.stopSpinner()
        if (elementId !== '') {
            const el = document.getElementById(elementId);
            //console.log(elementId, el)
            let h = 0;
            if (el) {
                //el.classList.add("focus")
                h = window.pageYOffset + el.getBoundingClientRect().top - 300
            }
            window.scrollTo({ top: h, behavior: "smooth" })
        }
    }

    validateRequiredFields = () => {
        let errors = this.state.errors, elementId = '';

        if (this.categoryValidation && this.categoryValidation())
            elementId = 'Category'
        else if (this.subCategoryValidation()) {
            elementId = 'SubCategory'
        } else if (this.stateValidation()) {
            elementId = 'State'
        } else if (this.cityValidation()) {
            elementId = 'City'
        } else if (this.localityValidation()) {
            elementId = 'Locality'
        } else if (this.neighbourhoodValidation()) {
            elementId = 'Neighbourhood'
        } else if (this.state.thumbnail_image) {
            //check if thumbnail image is uploaded
            elementId = 'thumbId'
            notifyUser('Thumbnail Image', 'Please Upload Thumbnail Image', 'info');
        } else if (!this.state.thumbnail_image_name.length) {
            //check if thumbnail image is selected
            errors.thumbnailImageSizeError = 'Please select thumbnail image'
            elementId = 'thumbId'
        } else if ((this.state.package === 'advanced' || this.state.package === 'premium') && this.state.thumbnail_description === '') {
            errors.thumbnailDescription = `Please Provide Thumbnail Description`
            elementId = 'thumb_description'
        } else if (this.state.package === 'basic' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews') {
            if (this.state.business_name === '') {
                const field = (this.state.selectedListing.value === 'business') ?
                    'Business Name' : (this.state.selectedListing.value === 'job') ?
                        'Job Title' : (this.state.selectedListing.value === 'event') ?
                            'Event Name' : (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') ?
                                'Title' : 'Title'
                errors.required_name = `Please Provide ${field}`
                elementId = 'business_name'
            } else if (this.state.selectedListing.value === 'business' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews') {
                if (this.state.selectedListing.value === 'business' && this.state.specialized_in.length < 150) {
                    errors.specializedIn = `Minimum 150 characters required`
                    elementId = 'specialized_in'
                } else if ((this.state.selectedListing.value === 'article' && this.state.specialized_in.length === 0) || (this.state.selectedListing.value === 'clips' && this.state.specialized_in.length === 0) || (this.state.selectedListing.value === 'news' && this.state.specialized_in.length === 0) || (this.state.selectedListing.value === 'photos' && this.state.specialized_in.length === 0) || (this.state.selectedListing.value === 'album' && this.state.specialized_in.length === 0) || (this.state.selectedListing.value === 'reviews' && this.state.specialized_in.length === 0)) {
                    notifyUser("Thumbnail Description", "Please Provide Thumbnail Description", type.INFO)
                    elementId = 'specialized_in'
                }
            } else if (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews') {
                if (this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'reviews') {
                    const wordCount = this.state.editorState.getCurrentContent().getPlainText('').split(/\s+/).filter(text => text.length > 0).length;
                    if (wordCount < 500 && this.state.selectedListing.value === 'news') {
                        notifyUser("My Page", "My Page Content should have a minimum of 500 words for Pages Listings.", type.INFO)
                        elementId = "editor"
                    }
                    if (wordCount < 500 && this.state.selectedListing.value === 'reviews') {
                        notifyUser("My Review", "My Review Content should have a minimum of 500 words for Reviews Listings.", type.INFO)
                        elementId = "editor"
                    }
                }
            }
            if (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album') {
                if (!this.state.editorState.getCurrentContent().hasText()) {
                    console.log(this.state.editorState.getCurrentContent().hasText())
                    errors.description = 'Description field cannot be empty'
                    { this.state.selectedListing.value === 'clips' ? notifyUser('Clip Description', 'Clip description field cannot be empty', type.INFO) : this.state.selectedListing.value === 'article' ? notifyUser('Video Description', 'Video description field cannot be empty', type.INFO) : notifyUser('Thumbnail Description', 'Description field cannot be empty', type.INFO) }
                    elementId = "editor"
                }
            }
            if (this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') {
                let emptyIndex = -1
                if (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips')
                    emptyIndex = this.state.elements[0].fileURL === null ? 0 : -1;
                else
                    emptyIndex = this.state.elements.findIndex((e) => {
                        return ((e.type === 'video' || e.type === 'image') && e.fileURL === null) || ((e.type === 'textarea' || e.type === 'text') && e.value === '')
                    })
                if (emptyIndex >= 0) {
                    elementId = 'article_news_data' + emptyIndex;
                    this.state.selectedListing.value === 'article' && notifyUser('Video', 'Video can not be empty', 'warning')
                    this.state.selectedListing.value === 'clips' && notifyUser('Clip', 'Clip can not be empty', 'warning');
                } else {
                    const unsavedIndex = this.state.elements.findIndex((e) => {
                        return ((e.type === 'video' || e.type === 'image') && e.file)
                    })
                    if (unsavedIndex >= 0) {
                        elementId = 'article_news_data' + unsavedIndex;
                        notifyUser('Insert', 'Insert item not uploaded', 'warning');
                    }
                }
            } if (this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews') {
                if (this.selectedSubCategories.length === 0) {
                    notifyUser('Select/Add Name', 'Select/Add Name field cannot be empty', 'warning')
                    elementId = 'SubCategory'
                }
            } else {
                if ((this.state.selectedListing.value === 'job' || this.state.selectedListing.value === 'event') && this.state.dateAndTimeElements[this.state.dateAndTimeElements.length - 1].toTime === '') {
                    errors.invalidTime = `Please Provide ${this.state.selectedListing.value === 'job' ? 'Interview' : 'Event'} Date And Time`
                    elementId = 'dateTimeElements';
                    elementId = 'runDate';
                } else if (this.state.selectedListing.value === 'job' && this.state.companyName === '') {
                    errors.company_name = 'Please Provide Company Name'
                    elementId = 'companyName'
                } else if (this.state.address_text === '') {
                    errors.address_info = `Please Provide Address`
                    elementId = 'addressInfo'
                } else if (this.state.contact_person === '') {
                    errors.contact_person = `Please Provide Contact Person Name`
                    elementId = 'contactPerson'
                } else if (this.state.contact_number === '') {
                    errors.contact_number = `Please Provide Contact Number`
                    elementId = 'contactNumber'
                }
            }
        }
        this.setState({ errors })
        this.scrollElementToCenter(elementId)
        return !validateForm(errors) || elementId.length > 0;
    }

    saveAddress = (e) => {
        if (this.state.readOnly) {
            if (this.state.package === 'advanced') {
                this.props.history.push(`/admin-panel/manage-posts/post/${this.state.hpId}/content/advanced`);
            } else if (this.state.package === 'premium') {
                this.props.history.push(`/admin-panel/manage-posts/post/${this.state.hpId}/content/premium`);
            } else {
                const location = {
                    pathname: `/admin-panel/manage-posts/post/orders/${this.state.hpId}`,
                    package: this.state.package,
                }
                this.props.history.push(location)
            }
            return;
        }
        if (this.validateRequiredFields()) {
            return;
        } else if (this.state.package === 'basic') {
            Loader.runSpinner()
            Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_GEO_API_KEY}&libraries=places&callback=initAutocomplete`);
            Geocode.enableDebug();
            Geocode.fromAddress(this.state.address_text + ',India').then(response => {
                const { lat, lng } = response.results[0].geometry.location;
                if (lat !== '') {
                    const addr = this.props.post_data.businessAddress
                    const business_address = [{
                        id: (addr && addr.length) ? addr[0].id : null,
                        addressIndex: 0,
                        address: this.state.address_text,
                        landmark: this.state.landmark,
                        lattitude: lat,
                        longitude: lng
                    }];
                    this.setState({ business_address })
                    Loader.stopSpinner();
                    this.uploadFileHandler(business_address)
                } else {
                    Loader.stopSpinner();
                    notifyUser("Set Address", this.state.address_text + " address is Invalid", notificationType.WARNING);
                }
            }, error => {
                Loader.stopSpinner();
                notifyUser("Set Address", this.state.address_text + " address is Invalid", notificationType.WARNING);
                this.scrollElementToCenter('addressInfo')
            })
        } else {
            this.uploadFileHandler(null)
        }
    }

    dateAndTimeElementId = 0;
    addDateAndTimeElements = (element) => {
        const dateAndTimeElements = [...this.state.dateAndTimeElements];
        if ((element.type1 === "date" && element.type2 === "fromTime") && element.type3 === "toTime") {
            this.dateAndTimeElementId++
            dateAndTimeElements[this.state.dateAndTimeElements.length] = { type1: element.type1, type2: element.type2, type3: element.type3, date: '', fromTime: '', toTime: '', id: this.dateAndTimeElementId }
        }
        this.setState({ dateAndTimeElements: dateAndTimeElements })
    }

    handleDateAndTime = (dateString, index, type) => {
        let errors = this.state.errors;
        const dateAndTimeElements = [...this.state.dateAndTimeElements]
        if (type === 'date') {
            dateAndTimeElements[index] = { ...dateAndTimeElements[index], date: dateString };
        } else if (type === 'fromTime') {
            const dateAndTimeElement = dateAndTimeElements[index];
            dateAndTimeElements[index] = { ...dateAndTimeElements[index], fromTime: dateString };
            if (dateAndTimeElement.toTime) {
                const fromTimeStamp = moment(dateString, 'hh:mm a')
                const toTimeStamp = moment(dateAndTimeElement.toTime, 'hh:mm a')
                if (toTimeStamp.isAfter(fromTimeStamp))
                    errors.invalidTime = ''
                else
                    errors.invalidTime = 'Please select valid start time'
            }

        } else if (type === 'toTime') {
            const dateAndTimeElement = dateAndTimeElements[index];
            dateAndTimeElements[index] = { ...dateAndTimeElements[index], toTime: dateString };
            if (dateAndTimeElement.fromTime) {
                const fromTimeStamp = moment(dateAndTimeElement.fromTime, 'hh:mm a')
                const toTimeStamp = moment(dateString, 'hh:mm a')
                if (toTimeStamp.isAfter(fromTimeStamp))
                    errors.invalidTime = ''
                else
                    errors.invalidTime = 'Please select valid end time'
            }
        }
        const dateTime = dateAndTimeElements[index];
        const dupIndex = dateAndTimeElements.findIndex((element, i) => {
            if (element.date === dateTime.date && i !== index) {
                const currentFromTime = moment(dateTime.fromTime, 'hh:mm a')
                const currentToTime = moment(dateTime.toTime, 'hh:mm a')
                const elementFromTime = moment(element.fromTime, 'hh:mm a')
                const elementToTime = moment(element.toTime, 'hh:mm a')

                if (elementFromTime.isSame(currentFromTime) && elementToTime.isSame(currentToTime))
                    return true;
                if (elementFromTime.isBetween(currentFromTime, currentToTime) || elementToTime.isBetween(currentFromTime, currentToTime))
                    return true;
                if (currentFromTime.isBetween(elementFromTime, elementToTime) || currentToTime.isBetween(elementFromTime, elementToTime))
                    return true;
            }
        })

        if (dupIndex > -1 && dupIndex !== index) {
            errors.invalidTime = 'Duplicate ' + this.state.selectedListing.label + ' Date And Time Found'
        }
        this.setState({ dateAndTimeElements: dateAndTimeElements, errors: errors })
    }

    handleJobDateAndTime = (dateString, index, type) => {
        let errors = this.state.errors;
        const dateAndTimeElements = [...this.state.dateAndTimeElements]
        if (type === 'date') {
            dateAndTimeElements[index] = { ...dateAndTimeElements[index], date: dateString };
        } else if (type === 'toTime') {
            dateAndTimeElements[index] = { ...dateAndTimeElements[index], toTime: dateString };

        }
        this.setState({ dateAndTimeElements: dateAndTimeElements, errors: errors })
    }

    deleteDateAndTime = (index) => {
        let errors = this.state.errors;
        errors.invalidTime = ''
        this.setState({ inValidFromTime: false, inValidToTime: false, errors })
        const dateAndTimeElements = [...this.state.dateAndTimeElements]
        const deletedItem = dateAndTimeElements.splice(index, 1)[0]
        if (deletedItem.interviewId)
            this.deletedDateAndTimeElements.push(deletedItem.interviewId)
        this.setState({ dateAndTimeElements: dateAndTimeElements })
    }

    disabledDate = (current) => {
        // Can not select days before today
        return current && current < moment().endOf('day').subtract(1, 'days');
    }

    handleOpenChangeFrom = open => {
        this.setState({ openFrom: true, openTo: false });
    };
    handleOpenChangeTo = open => {
        this.setState({ openTo: true, openFrom: false });
    };
    handleCloseFrom = () => this.setState({ openFrom: false });
    handleCloseTo = () => this.setState({ openTo: false });

    handleCancel = () => { this.props.clearPropsData(); this.props.history.push('/manageposts') }

    descriptionLabel = () => {
        if ((this.state.selectedListing !== '') && ((this.state.selectedListing.value === 'business') && (this.state.package === 'basic')))
            return 'Specialized In *'
        else if ((this.state.selectedListing.value === 'job') && (this.state.package === 'basic'))
            return 'Job Description *'
        else if ((this.state.selectedListing.value === 'event') && (this.state.package === 'basic'))
            return 'Event Description *'
        else if ((this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') && (this.state.package === ''))
            return 'Thumbnail Description *'
        else if (((this.state.selectedListing.value === 'business') || (this.state.selectedListing.value === 'job')
            || (this.state.selectedListing.value === 'event')) && ((this.state.package === 'advanced') || (this.state.package === 'premium')))
            return 'Thumbnail Description *'
        else return ''
    }

    titleLabel = () => {
        if ((this.state.selectedListing.value === 'business') && (this.state.package === 'basic'))
            return 'Business Name *'
        else if ((this.state.selectedListing.value === 'job') && (this.state.package === 'basic'))
            return 'Job Title *'
        else if ((this.state.selectedListing.value === 'event') && (this.state.package === 'basic'))
            return 'Event Name *'
        else if ((this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') && (this.state.package === ''))
            return 'Title *'
        else return ''
    }

    selectDateType = (e, index) => {
        let errors = this.state.errors;
        this.state.dateAndTimeElements.forEach(e => {
            if (e.interviewId)
                this.deletedDateAndTimeElements.push(e.interviewId)
        })
        console.log(this.deletedDateAndTimeElements)
        const selectedDateType = e.target.value;
        this.setState({ dateAndTimeElements: [{ date: "", fromTime: "", id: 1, toTime: "", type1: "date", type2: "fromTime", type3: "toTime" }], errors: errors, selectedDateType: selectedDateType })
        localStorage.setItem('selectedDateType', selectedDateType);
    }

    render() {
        const { errors } = this.state;
        const label = this.state.selectedListing.value === 'job' ? this.state.selectedDateType === 'date' ? 'Last Date' : 'Interview Date & Time' : this.state.selectedDateType === 'date' ? 'Last Date' : 'Event Date & Time'
        // $('#menuHandler').on('change', function () {
        //     if (this.value === "dateTime") {
        //         $('#dateTimeBtn').show();
        //         $('#runDate').show();
        //     } else {
        //         if (this.value === "date")
        //             $('#runDate').hide();
        //         $('#dateTimeBtn').hide();
        //     }
        // });
        return (
            <div>
                {this.state.showConfirmationPopup && <ConfirmPopup confirm={this.removeElement} cancel={this.closeConfirmationPopup} data={this.currentElement} />}
                <div className="grid_container">
                    <div id='addpost-form'>
                        <fieldset disabled={this.state.readOnly}>
                            <div className={`row ${styles.addpost_section} form-row`}>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <TitleHeading text={'Set Post:'} />
                                    <div className="form-row post_section">
                                        <div className="form-group col-md-2 label">
                                            <FormLabels text={'Select Post Type'} htmlFor="inputZip" />
                                        </div>
                                        <div id="listingFocus" className="form-group col-md-8">
                                            <Select
                                                placeholder="Post Type"
                                                value={this.state.selectedListing}
                                                onChange={this.handleListingType}
                                                options={this.state.listingTypeOptions}
                                                isDisabled={this.state.hpId}
                                            />
                                        </div>
                                    </div>
                                    {((this.state.selectedListing !== '') && ((this.state.selectedListing.value === 'business') || (this.state.selectedListing.value === 'job') || (this.state.selectedListing.value === 'event'))) ?
                                        <PackageSelectors
                                            hpId={this.state.hpId}
                                            selectedListingType={this.state.selectedListing}
                                            package={this.state.package}
                                            handleChange={this.setPackage}
                                        /> : null}
                                </div>
                            </div>
                            {this.state.selectedListing.value === 'news' && this.state.myPages === false ?
                                <div className='qualify'>
                                    <TitleHeading text={'AajuBaaju My Pages:'} />
                                    <div className="col-md-12 text-left">
                                        <p>Submit an article written in MS Word Document following the guidelines in <Link to='/help/pagesguide'>https://aajubaaju.in/help/pagesguide </Link> to moderation@aajubaaju.in</p>
                                        <p><strong>Note:</strong> AajuBaaju My Pages qualification has only 1 attempt.</p>
                                        <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                    </div>
                                </div>
                                : this.state.selectedListing.value === 'article' && this.state.myVideos === false ?
                                    <div className='qualify'>
                                        <TitleHeading text={'AajuBaaju My Videos'} />
                                        <div className="col-md-12 text-left">
                                            <p>Submit a Video link following the guidelines in <Link to='/help/videosguide'>https://aajubaaju.in/help/videosguide</Link> to moderation@aajubaaju.in</p>
                                            <p><strong>Note:</strong> AajuBaaju My Videos qualification has only 3 attempts.</p>
                                            <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                        </div>
                                    </div> :
                                    this.state.selectedListing.value === 'clips' && this.state.myClips === false ?
                                        <div className='qualify'>
                                            <TitleHeading text={'AajuBaaju My Clips'} />
                                            <div className="col-md-12 text-left">
                                                <p>Submit a Clip link following the guidelines in <Link to='/help/clipsguide'>https://aajubaaju.in/help/clipsguide</Link> to moderation@aajubaaju.in</p>
                                                <p><strong>Note:</strong> AajuBaaju My Clips qualification has only 3 attempts.</p>
                                                <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                            </div>
                                        </div> :
                                        this.state.selectedListing.value === 'reviews' && this.state.myReviews === false ?
                                            <div className='qualify'>
                                                <TitleHeading text={'AajuBaaju My Reviews'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit a Review written in MS Word Document following the guidelines in <Link to='/help/reviewsguide'>https://aajubaaju.in/help/reviewsguide</Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Reviews qualification has only 1 attempt.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                            </div> :
                                            this.state.selectedListing.value === 'album' && this.state.myAlbum === false ?
                                                <div className='qualify'>
                                                    <TitleHeading text={'AajuBaaju My Album'} />
                                                    <div className="col-md-12 text-left">
                                                        <p>Submit a Album Story by sending a sequence of minimum 10 photos following the guidelines in <Link to='/help/photosguide'>https://aajubaaju.in/help/photosguide</Link> to moderation@aajubaaju.in</p>
                                                        <p><strong>Note:</strong> AajuBaaju My Album qualification has only 3 attempt.</p>
                                                        <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                    </div>
                                                </div> :
                                                (this.state.selectedListing.value === 'photos' && this.state.myPhotos === false) ?
                                                    <div className='qualify'>
                                                        <TitleHeading text={'AajuBaaju My Photos'} />
                                                        <div className="col-md-12 text-left">
                                                            <p>Submit a Photo Story by sending a sequence of minimum 10 photos following the guidelines in <Link to='/help/photosguide'>https://aajubaaju.in/help/photosguide</Link> to moderation@aajubaaju.in</p>
                                                            <p><strong>Note:</strong> AajuBaaju My Photos qualification has only 3 attempts.</p>
                                                            <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                        </div>
                                                    </div> : <>
                                                        {(this.state.package === 'basic' || this.state.package === 'advanced' || this.state.package === 'premium' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') ?
                                                            <div className={`row ${styles.addpost_section} form-row`}>
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <Suspense fallback={<div>Loading Category Settings...</div>}>
                                                                        <SetCategory
                                                                            showCategory={this.state.selectedListing.value !== 'article' && this.state.selectedListing.value !== 'clips' && this.state.selectedListing.value !== 'news' && this.state.selectedListing.value !== 'photos' && this.state.selectedListing.value !== 'album' && this.state.selectedListing.value !== 'reviews'}
                                                                            setCategoryValidationMethod={this.setCategoryValidationMethod}
                                                                            setSubCategoryValidationMethod={this.setSubCategoryValidationMethod}
                                                                            heading={this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews' ? (this.state.selectedListing.value === 'news') ? 'Set AajuBaaju My Pages Name' : (this.state.selectedListing.value === 'reviews') ? 'Set AajuBaaju My Reviews Name' : (this.state.selectedListing.value === 'photos') ? 'Set AajuBaaju My Photos Name' : (this.state.selectedListing.value === 'album') ? 'Set AajuBaaju My Album Name' : (this.state.selectedListing.value === 'clips') ? 'Set AajuBaaju My Clips Name' : 'Set AajuBaaju My Videos Name' : 'Set Category'} isMulti
                                                                            allCategories={this.state.allCategories}
                                                                            _handleNewCategory={this._handleNewCategory}
                                                                            newFilterForm
                                                                            selectedCategories={this.state.selectedCategory}
                                                                            selectedSubCategories={this.state.selectedSubCategory}
                                                                            _handleCategory={this._handleCategory}
                                                                            _handleSubCategory={this._handleSubCategory}
                                                                            labelClass={'col-md-2'}
                                                                            listing={this.listingType}
                                                                        />
                                                                    </Suspense>
                                                                    {/* <div className='row form-row'>
                                                <div className='form-group col-md-2'></div>
                                                <span className={`form-group col-md-8 text-left ${styles.error}`}>{errors.sub_Categories}</span>
                                            </div> */}
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {(this.state.package === 'basic' || this.state.package === 'advanced' || this.state.package === 'premium' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews' || this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips') ?
                                                            <div className={`row ${styles.addpost_section} form-row`}>
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <Suspense fallback={<div>Loading Location Settings...</div>}>
                                                                        <SetLocation
                                                                            heading='Areas Served' isMulti
                                                                            setFilterValidationMethod={this.setLocationValidationMethod}
                                                                            allLocations={this.state.fullAddress}
                                                                            _handleNewLocation={this._handleNewLocation}
                                                                            newFilterForm
                                                                            selectedStates={this.state.selectedState}
                                                                            selectedCities={this.state.selectedCity}
                                                                            selectedLocalities={this.state.selectedLocality}
                                                                            selectedNeighbourhoods={this.state.selectedNeighbourhood}
                                                                            _handleState={this._handleState}
                                                                            _handleCity={this._handleCity}
                                                                            _handleLocality={this._handleLocality}
                                                                            _handleNeighbourhood={this._handleNeighbourhood}
                                                                            addressOptions
                                                                            setAddressOptions={this.setAddressOptions}
                                                                            labelClass={'col-md-2'}
                                                                            setStateValidationMethod={this.setStateValidationMethod}
                                                                            setCityValidationMethod={this.setCityValidationMethod}
                                                                            setLocalityValidationMethod={this.setLocalityValidationMethod}
                                                                            setNeighbourhoodValidationMethod={this.setNeighbourhoodValidationMethod}
                                                                        />
                                                                    </Suspense>
                                                                </div>

                                                            </div>
                                                            : null}

                                                        <div className={`row ${styles.addpost_section} form-row`}>
                                                            <div className="form-group col-md-12">
                                                                {((this.state.selectedListing.value === 'business') && ((this.state.package === 'basic') || (this.state.package === 'advanced') || (this.state.package === 'premium'))) || ((this.state.selectedListing.value === 'job') && ((this.state.package === 'basic') || (this.state.package === 'advanced'))) || ((this.state.selectedListing.value === 'event') && ((this.state.package === 'basic') || (this.state.package === 'advanced'))) || ((this.state.selectedListing.value === 'article')) || ((this.state.selectedListing.value === 'clips')) || ((this.state.selectedListing.value === 'news')) || ((this.state.selectedListing.value === 'photos')) || ((this.state.selectedListing.value === 'album')) || ((this.state.selectedListing.value === 'reviews')) ?
                                                                    <div className="row post_section form-row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <TitleHeading text={'Set Listing:'} />
                                                                        </div>
                                                                        <div className="form-group col-md-2 label">
                                                                            <FormLabels text={'Thumbnail Image *'} htmlFor="inputZip" />
                                                                        </div>
                                                                        <div className="form-group col-md-8">
                                                                            <FileInput
                                                                                file_url={this.state.thumbnail_image_url}
                                                                                file={this.state.thumbnail_image}
                                                                                file_name={this.state.thumbnail_image_name}
                                                                                disabled={this.state.thumbnailImageUploading}
                                                                                onFileSelect={e => { this.onImageChange(e.target.files[0]) }}
                                                                                fileFormats=".png, .jpg, .jpeg, .gif"
                                                                                labelText={'Choose Image'}
                                                                            />
                                                                            <span className={`${styles.error}`}>{errors.thumbnailImageSizeError}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-2">
                                                                            {(!this.state.thumbnailImageUploading) ?
                                                                                <div>
                                                                                    {(this.state.thumbnail_image) ?
                                                                                        <button type='button' className="btn btn-success media-btn" onClick={this.uploadThumbnail}>
                                                                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                                                                        </button> : null}
                                                                                </div> :
                                                                                <div> <AnimatedText text={'uploading'} /> </div>}
                                                                        </div>
                                                                    </div> : null}
                                                                {(this.state.package === 'basic') &&
                                                                    <div className="row form-row">
                                                                        <div className="form-group col-md-2 label">
                                                                            <FormLabels text={this.titleLabel()} htmlFor="inputZip" />
                                                                        </div>
                                                                        <div className="form-group col-md-8">
                                                                            <input type="text" className="form-control" placeholder=""
                                                                                name="required_name" id='business_name'
                                                                                value={this.state.business_name} required
                                                                                onChange={(event) => {
                                                                                    this.setState({ business_name: event.target.value })
                                                                                    this.handleChange(event)
                                                                                }} />
                                                                            <Errors>{errors.required_name}</Errors>
                                                                        </div>
                                                                    </div>}
                                                                <div className="row form-row">
                                                                    <div className="form-group col-md-2 label">
                                                                        <FormLabels text={this.descriptionLabel()} htmlFor="inputZip" />
                                                                    </div>
                                                                    {((this.state.selectedListing.value === 'business') && ((this.state.package === 'basic'))) || ((this.state.selectedListing.value === 'job') && ((this.state.package === 'basic'))) || ((this.state.selectedListing.value === 'event') && ((this.state.package === 'basic'))) || ((this.state.selectedListing.value === 'article') && (this.state.package === '')) || ((this.state.selectedListing.value === 'clips') && (this.state.package === '')) || ((this.state.selectedListing.value === 'news') && (this.state.package === '')) || ((this.state.selectedListing.value === 'photos') && (this.state.package === '')) || ((this.state.selectedListing.value === 'album') && (this.state.package === '')) || ((this.state.selectedListing.value === 'reviews') && (this.state.package === '')) ?
                                                                        <div className="form-group col-md-8">
                                                                            <textarea className="form-control" id='specialized_in' aria-label="With textarea"
                                                                                value={this.state.specialized_in} name='specializedIn' minlength="150"
                                                                                onChange={(event) => {
                                                                                    this.setState({ specialized_in: event.target.value })
                                                                                    this.handleChange(event)
                                                                                }}
                                                                            ></textarea>
                                                                            <Errors>{errors.specializedIn}</Errors>
                                                                        </div> : null}
                                                                    {((this.state.selectedListing.value === 'business') && ((this.state.package === 'advanced') || (this.state.package === 'premium'))) || ((this.state.selectedListing.value === 'job') && ((this.state.package === 'advanced'))) || ((this.state.selectedListing.value === 'event') && ((this.state.package === 'advanced'))) ?
                                                                        <div className="form-group col-md-8">
                                                                            <textarea className="form-control" id='thumb_description' aria-label="With textarea"
                                                                                value={this.state.thumbnail_description} name='thumbnailDescription' maxLength="520"
                                                                                onChange={(event) => {
                                                                                    this.setState({ thumbnail_description: event.target.value })
                                                                                    this.handleChange(event)
                                                                                }} ></textarea>
                                                                            <Errors>{errors.thumbnailDescription}</Errors>
                                                                        </div> : null}
                                                                </div>
                                                                {(this.state.selectedListing.value === 'article' || this.state.selectedListing.value === 'clips' || this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'photos' || this.state.selectedListing.value === 'album' || this.state.selectedListing.value === 'reviews') &&
                                                                    <div className="row form-row">
                                                                        <div className="form-group col-md-2 label">
                                                                            <FormLabels text={this.titleLabel()} htmlFor="inputZip" />
                                                                        </div>
                                                                        <div className="form-group col-md-8">
                                                                            <input type="text" className="form-control" placeholder=""
                                                                                name="required_name" id='business_name'
                                                                                value={this.state.business_name} required
                                                                                onChange={(event) => {
                                                                                    this.setState({ business_name: event.target.value })
                                                                                    this.handleChange(event)
                                                                                }} />
                                                                            <Errors>{errors.required_name}</Errors>
                                                                        </div>
                                                                    </div>}

                                                                {(this.state.selectedListing !== '') && (this.state.selectedListing.value === 'job') && (this.state.package === 'basic') ?
                                                                    <div>
                                                                        <div className="row form-row">
                                                                            <div className="form-group col-md-2 label">
                                                                                <FormLabels text={'Experience'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <input type="text" className="form-control" name="experience"
                                                                                    placeholder="Experience" value={this.state.experience}
                                                                                    onChange={(event) => this.setState({ experience: event.target.value })} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="row form-row">
                                                                            <div className="form-group col-md-2 label">
                                                                                <FormLabels text={'Salary'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <input type="text" name="salary" className="form-control"
                                                                                    placeholder="salary" value={this.state.salary}
                                                                                    onChange={(event) => this.setState({ salary: event.target.value })} />
                                                                            </div>
                                                                        </div>
                                                                    </div> : null}
                                                                {(this.state.selectedListing !== '' && ((this.state.selectedListing.value === 'job' || this.state.selectedListing.value === 'event')) && (this.state.package === 'basic')) ?
                                                                    <div>
                                                                        <div className="row form-row">
                                                                            {(this.state.selectedListing !== "" && (this.state.selectedListing.value === "job")) ?
                                                                                <div className="col-md-2 label">
                                                                                    <select id='menuHandler' ref={this.selectRef} className={`${styles.menu}`} onChange={this.selectDateType} value={this.state.selectedDateType}>
                                                                                        <option value='dateTime' htmlFor="inputZip">Interview Date &amp; Time</option>
                                                                                        <option value='date' htmlFor="inputZip">Last Date </option>
                                                                                    </select>
                                                                                </div> : (this.state.selectedListing.value === "event") ?
                                                                                    <div className="form-group col-md-2 label">
                                                                                        <select id='menuHandler' ref={this.selectRef} style={{ color: '#000080', fontWeight: '600', width: '150px', marginTop: '.2rem', textAlign: 'right' }} onChange={this.selectDateType} value={this.state.selectedDateType}>
                                                                                            <option value='dateTime' htmlFor="inputZip">Event Date &amp; Time</option>
                                                                                            <option value='date' htmlFor="inputZip"> Event Last Date </option>
                                                                                        </select>
                                                                                    </div> : null}
                                                                            <div className="col-md-8" id='dateTimeElements'>

                                                                                {this.state.dateAndTimeElements.map((dateAndTime, index) => {
                                                                                    const key = dateAndTime.interviewId ? dateAndTime.interviewId : dateAndTime.id;

                                                                                    return (
                                                                                        <div>
                                                                                            <div className="row form-row" key={key} >
                                                                                                <div className="form-group col-md-5">
                                                                                                    <DatePicker className="basic_date_time" showToday={false} onChange={(date, dateString) => this.handleDateAndTime(dateString, index, dateAndTime.type1)}
                                                                                                        disabledDate={this.disabledDate} format='DD/MM/YYYY'
                                                                                                        value={(dateAndTime.date) ? moment(dateAndTime.date, 'DD/MM/YYYY') : null} />
                                                                                                </div>
                                                                                                {this.state.selectedDateType === 'dateTime' && <div className="form-group col-md-3" id="runDate">
                                                                                                    <TimePicker defaultOpenValue={moment('00:00:00', 'h:mm a')} className="basic_date_time" use12Hours format="h:mm a" minuteStep={30}
                                                                                                        onOpenChange={this.handleOpenChangeFrom} value={(dateAndTime.fromTime) ? moment(dateAndTime.fromTime, 'h:mm a') : null} disabled={!dateAndTime.date}
                                                                                                        onChange={(time, timeString) => this.handleDateAndTime(timeString, index, dateAndTime.type2)}
                                                                                                        id={"fromtime" + key} placeholder="Select Start Time"
                                                                                                    />
                                                                                                </div>}
                                                                                                <div className="form-group col-md-3">
                                                                                                    <TimePicker defaultOpenValue={moment('00:00:00', 'h:mm a')} className="basic_date_time" use12Hours format="h:mm a" minuteStep={30}
                                                                                                        onOpenChange={this.handleOpenChangeTo} onChange={(time, timeString) => this.handleDateAndTime(timeString, index, dateAndTime.type3)}
                                                                                                        value={(dateAndTime.toTime) ? moment(dateAndTime.toTime, 'h:mm a') : null} disabled={this.state.selectedDateType === 'date' ? !dateAndTime.date : !dateAndTime.fromTime}
                                                                                                        id={"totime" + key} placeholder="Select End time"
                                                                                                    />
                                                                                                </div>
                                                                                                {index && this.state.selectedDateType === 'dateTime' ?
                                                                                                    <div className="form-group col-md-1">
                                                                                                        <button type='button' className="btn btn-danger basic_time_delete" onClick={() => this.deleteDateAndTime(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                                                                                    </div> : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                <div className="row form-row">
                                                                                    <div className="col-md-10">
                                                                                        <div className="text-right"><Errors>{errors.invalidTime}</Errors></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {this.state.selectedListing.value === 'job' || this.state.selectedListing.value === 'event' && this.state.selectedDateType === 'date' ? null :
                                                                                (this.state.readOnly || errors.invalidTime.length || !this.state.dateAndTimeElements[this.state.dateAndTimeElements.length - 1].toTime) ? null :

                                                                                    <div className="add_button col-md-2">
                                                                                        <button id="dateTimeBtn" className="btn btn-link" type="button" onClick={() => this.addDateAndTimeElements({ type1: "date", type2: "fromTime", type3: "toTime" })}>
                                                                                            + Add</button>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            (this.state.selectedListing !== "" && (this.state.selectedListing.value === "job")) ?
                                                                                <div className="row form-row">
                                                                                    <div className="form-group col-md-2 label">
                                                                                        <FormLabels text={'Company Name *'} htmlFor="inputZip" />
                                                                                    </div>
                                                                                    <div className="form-group col-md-8">
                                                                                        <input type="text" name="company_name" className="form-control"
                                                                                            id='companyName'
                                                                                            placeholder="Company Name" value={this.state.companyName}
                                                                                            onChange={(e) => {
                                                                                                this.setState({ companyName: e.target.value })
                                                                                                this.handleChange(e)
                                                                                            }} />
                                                                                        <Errors>{errors.company_name}</Errors>
                                                                                    </div>
                                                                                </div> : null
                                                                        } </div> : null}
                                                                {((this.state.selectedListing !== '') && ((this.state.selectedListing.value === 'business') || (this.state.selectedListing.value === 'job') || (this.state.selectedListing.value === 'event')) && this.state.package === 'basic') ?
                                                                    <div className={`row ${styles.addpost_section} form-row`}>
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="form-row row">
                                                                                <div className="form-group col-md-2 label">
                                                                                    {((this.state.selectedListing !== '') && (this.state.selectedListing.value === 'business')) ?
                                                                                        (
                                                                                            <FormLabels text={'Address *'} htmlFor="inputZip" />
                                                                                        )
                                                                                        : (this.state.selectedListing !== '') && (this.state.selectedListing.value === 'job' || this.state.selectedListing.value === 'event') ?
                                                                                            <FormLabels text={'Venue *'} htmlFor="inputZip" />
                                                                                            : null}
                                                                                </div>
                                                                                <div className="form-group col-md-8">
                                                                                    <input type="text" className="form-control" placeholder="Address"
                                                                                        value={this.state.address_text} required name='address_info'
                                                                                        id="addressInfo"
                                                                                        onChange={(event) => {
                                                                                            this.setState({ address_text: event.target.value })
                                                                                            this.handleChange(event)
                                                                                        }} />
                                                                                    <Errors>{errors.address_info}</Errors>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row form-row">
                                                                                <div className="form-group col-md-2 label">
                                                                                    <FormLabels text={'Landmark'} htmlFor="inputZip" />
                                                                                </div>
                                                                                <div className="form-group col-md-8">
                                                                                    <input type="text" className="form-control" placeholder="Landmark"
                                                                                        value={this.state.landmark}
                                                                                        onChange={(event) => this.setState({ landmark: event.target.value })} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row form-row">
                                                                                <div className="form-group col-md-2 label">
                                                                                    <FormLabels text={'Contact Person *'} htmlFor="inputZip" />
                                                                                </div>
                                                                                <div className="form-group col-md-8">
                                                                                    <input type="text" className="form-control" placeholder="Contact Person"
                                                                                        name="contact_person" id="contactPerson"
                                                                                        value={this.state.contact_person} required
                                                                                        onChange={(event) => {
                                                                                            this.setState({ contact_person: event.target.value })
                                                                                            this.handleChange(event)
                                                                                        }} />
                                                                                    <Errors>{errors.contact_person}</Errors>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row form-row">
                                                                                <div className="form-group col-md-2 label">
                                                                                    <FormLabels text={'Mobile/Landline *'} htmlFor="inputZip" />
                                                                                </div>
                                                                                <div className="form-group col-md-8">
                                                                                    <input type="tel" className="form-control"
                                                                                        name="contact_number" placeholder="Contact Number"
                                                                                        id='contactNumber'
                                                                                        value={this.state.contact_number}
                                                                                        //maxLength="10"
                                                                                        onChange={(event) => { this.setState({ contact_number: event.target.value }); this.handleChange(event) }}
                                                                                        //onBlur={this.handleChange}
                                                                                        noValidate />
                                                                                    <Errors>{errors.contact_number}</Errors>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                {((this.state.selectedListing.value === 'business') || (this.state.selectedListing.value === 'job') || (this.state.selectedListing.value === 'event')) && (this.state.package === 'basic') ?
                                                                    <div>

                                                                        <div className="row form-row">
                                                                            <div className="form-group col-md-2 label">
                                                                                <FormLabels text={'Email Address'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <input type="email" name="email_address" className="form-control"
                                                                                    placeholder="Email Address" value={this.state.email_address}
                                                                                    onChange={(event) => { this.setState({ email_address: event.target.value }); this.handleChange(event) }}
                                                                                    noValidate />
                                                                                <Errors>{errors.email_address}</Errors>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row form-row">
                                                                            <div className="form-group col-md-2 label">
                                                                                <FormLabels text={'Website'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <input type="text" className={`form-control ${(this.state.showWWW || this.state.website.length) ? `${styles.website_input}` : ''}`}
                                                                                    placeholder={(this.state.showWWW) ? '' : 'Website'}
                                                                                    name="website" value={this.state.website}
                                                                                    onFocus={() => this.setState({ showWWW: true })}
                                                                                    onChange={(event) => { this.setState({ website: event.target.value }); this.handleChange(event) }}
                                                                                    noValidate />
                                                                                {(this.state.showWWW || this.state.website.length > 0) ? <label className={`${styles.website_prefix_label}`}></label> : null}
                                                                                <Errors>{errors.website}</Errors>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null}

                                                                {
                                                                    (this.state.selectedListing.value === 'photos') &&
                                                                    <>
                                                                        <div className='row form-row'>
                                                                            <div className="form-group col-md-2 text-right" id="my_photos">
                                                                                <FormLabels text={'Photo Story*'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <Editor
                                                                                    editorState={this.state.editorState}
                                                                                    spellCheck={true}
                                                                                    toolbar={{
                                                                                        options: ['fontSize', 'inline', 'link',],
                                                                                        inline: {
                                                                                            options: ['bold', 'italic', 'underline']
                                                                                        },
                                                                                        fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40], },
                                                                                        link: {
                                                                                            className: undefined,
                                                                                            options: ["link", "unlink"],
                                                                                            defaultTargetOption: "_blank",
                                                                                            showOpenOptionOnHover: false,
                                                                                            defaultValue: "",
                                                                                        },
                                                                                    }}
                                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                                    wrapperClassName="wrapper-class"
                                                                                    editorClassName="editor-class"
                                                                                    toolbarClassName="toolbar-class"
                                                                                    id="editor"
                                                                                />
                                                                                <span className={`${styles.error}`}>{errors.description}</span>
                                                                            </div>
                                                                        </div>
                                                                        <MyPagesInput
                                                                            state={this.state}
                                                                            writeContent={this.writeContent}
                                                                            handleChange={this.handleChange}
                                                                            removeConfirmation={this.removeConfirmation}
                                                                            uploadMedia={this.uploadMedia}
                                                                            selectMedia={this.selectMedia}
                                                                            addElements={this.addElements}
                                                                            inputStatus={this.state.elementStatus}
                                                                            elements={this.state.elements}
                                                                        />
                                                                        <div className='row form-row'>
                                                                            <div className='form-group col-md-2'></div>
                                                                            <span className={`form-group col-md-8 text-left ${styles.error}`}>{errors.uploadError}</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    (this.state.selectedListing.value === 'album') &&
                                                                    <>
                                                                        <div className='row form-row'>
                                                                            <div className="form-group col-md-2 text-right" id="my_photos">
                                                                                <FormLabels text={'Album Story*'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <Editor
                                                                                    editorState={this.state.editorState}
                                                                                    spellCheck={true}
                                                                                    toolbar={{
                                                                                        options: ['fontSize', 'inline', 'link',],
                                                                                        inline: {
                                                                                            options: ['bold', 'italic', 'underline']
                                                                                        },
                                                                                        fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40], },
                                                                                        link: {
                                                                                            className: undefined,
                                                                                            options: ["link", "unlink"],
                                                                                            defaultTargetOption: "_blank",
                                                                                            showOpenOptionOnHover: false,
                                                                                            defaultValue: "",
                                                                                        },
                                                                                    }}
                                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                                    wrapperClassName="wrapper-class"
                                                                                    editorClassName="editor-class"
                                                                                    toolbarClassName="toolbar-class"
                                                                                    id="editor"
                                                                                />
                                                                                <span className={`${styles.error}`}>{errors.description}</span>
                                                                            </div>
                                                                        </div>
                                                                        <MyPagesInput
                                                                            state={this.state}
                                                                            writeContent={this.writeContent}
                                                                            handleChange={this.handleChange}
                                                                            removeConfirmation={this.removeConfirmation}
                                                                            uploadMedia={this.uploadMedia}
                                                                            selectMedia={this.selectMedia}
                                                                            addElements={this.addElements}
                                                                            inputStatus={this.state.elementStatus}
                                                                            elements={this.state.elements}
                                                                        />
                                                                        <div className='row form-row'>
                                                                            <div className='form-group col-md-2'></div>
                                                                            <span className={`form-group col-md-8 text-left ${styles.error}`}>{errors.uploadError}</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    (this.state.selectedListing.value === 'article' && this.state.elements.length > 1 || this.state.selectedListing.value === 'clips' && this.state.elements.length > 1) &&
                                                                    <>
                                                                        <MyVideoInput
                                                                            handleVideoSelect={this.selectMedia}
                                                                            handleDescription={this.writeContent}
                                                                            elements={this.state.elements}
                                                                            handleUpload={this.uploadMedia}
                                                                            inputStatus={this.state.elementStatus}
                                                                            listingType={this.state.selectedListing.value}
                                                                        />
                                                                        <div className='row form-row mypages_editor'>
                                                                            <div className="form-group col-md-2 text-right" id="my_photos">
                                                                                <FormLabels text={this.state.selectedListing.value === 'article' ? 'Video Description*' : 'Clip Description*'} htmlFor="inputZip" />
                                                                            </div>
                                                                            <div className="form-group col-md-8">
                                                                                <Editor
                                                                                    editorState={this.state.editorState}
                                                                                    spellCheck={true}
                                                                                    toolbar={{
                                                                                        options: ['fontSize', 'inline', 'link',],
                                                                                        inline: {
                                                                                            options: ['bold', 'italic', 'underline']
                                                                                        },
                                                                                        fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40], },
                                                                                        link: {
                                                                                            className: undefined,
                                                                                            options: ["link", "unlink"],
                                                                                            defaultTargetOption: "_blank",
                                                                                            showOpenOptionOnHover: false,
                                                                                            defaultValue: "",
                                                                                        },
                                                                                    }}
                                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                                    wrapperClassName="wrapper-class"
                                                                                    editorClassName="editor-class"
                                                                                    toolbarClassName="toolbar-class"
                                                                                    id="editor"
                                                                                />
                                                                                <span className={`${styles.error}`}>{errors.description}</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    (this.state.selectedListing.value === 'news' || this.state.selectedListing.value === 'reviews') &&
                                                                    <div className='row form-row mypages_editor'>
                                                                        <div className="form-group col-md-2 text-right">
                                                                            {this.state.selectedListing.value === 'news' ? <FormLabels text={'My Page Content*'} htmlFor="inputZip" /> : <FormLabels text={'My Review Content*'} htmlFor="inputZip" />}
                                                                        </div>
                                                                        <div className="form-group col-md-8" id="my_photos">
                                                                            <Editor
                                                                                editorState={this.state.editorState}
                                                                                spellCheck={true}
                                                                                toolbar={{
                                                                                    options: ['inline', 'emoji', 'colorPicker', 'fontSize', 'list', 'textAlign', 'link', 'embedded', 'image',],
                                                                                    // blockType: {
                                                                                    //     inDropdown: true,
                                                                                    //     options: ['Normal', 'H2', 'H4', 'H6']
                                                                                    // },
                                                                                    colorPicker: { visible: true },
                                                                                    link: {
                                                                                        className: undefined,
                                                                                        options: ["link", "unlink"],
                                                                                        defaultTargetOption: "_blank",
                                                                                        showOpenOptionOnHover: false,
                                                                                        defaultValue: "",
                                                                                    },
                                                                                    fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40], },
                                                                                    embedded: {
                                                                                        embedCallback: undefined,
                                                                                        defaultSize: { height: '600px', width: '800px', },
                                                                                    },
                                                                                    emoji: {
                                                                                        emojis: [
                                                                                            '😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', '😘', '😋', '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭',
                                                                                            '🤓', '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶', '😏', '😒', '🙄', '😬', '🤒', '🤒', '😴', '🤠', '🥳', '🧐', '😲', '😰', '😫', '😤', '😡', '😠', '😈',
                                                                                            '👿', '💬', '💭', '👁️‍🗨️', '🤚', '🖐', '✋', '🖖', '👌', '✌', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '👇', '☝', '👍', '👎', '👏', '🙌', '👐', '🤝', '🙏',
                                                                                            '✍', '🧒', '👨', '🧔', '👱‍♂️', '👨‍🦳', '👴', '💁‍♂️', '💁‍♂️', '🙋‍♀️', '🙋‍♂️', '🙇', '🙇‍♀️', '🤷‍♂️', '🤷‍♀️', '👨‍⚕️', '👩‍⚕️', '👨‍🎓', '👩‍🎓', '👨‍🏫', '👩‍🏫', '👨‍⚖️', '👩‍⚖️', '👨‍🌾', '👩‍🌾', '👨‍🍳',
                                                                                            '👩‍🍳'
                                                                                        ],
                                                                                    },
                                                                                    image: {
                                                                                        urlEnabled: true,
                                                                                        uploadEnabled: true,
                                                                                        alignmentEnabled: true,
                                                                                        uploadCallback: this.editorImageUpload,
                                                                                        previewImage: true,
                                                                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                                                        alt: { present: false, mandatory: false },
                                                                                        defaultSize: { height: 'auto', width: 'auto', },
                                                                                    },
                                                                                }}
                                                                                onEditorStateChange={this.onEditorStateChange}
                                                                                wrapperClassName="wrapper-class"
                                                                                editorClassName="editor-class"
                                                                                toolbarClassName="toolbar-class"
                                                                                id="editor"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(!this.state.readOnly) ?
                                                                    ((this.state.package === 'basic') || (this.state.package === 'advanced') || (this.state.package === 'premium') || (this.state.selectedListing.value === 'article') || (this.state.selectedListing.value === 'clips') || (this.state.selectedListing.value === 'news') || (this.state.selectedListing.value === 'photos') || (this.state.selectedListing.value === 'album') || (this.state.selectedListing.value === 'reviews')) ?
                                                                        <div className="row form-row">
                                                                            <div className="col-md-10 text-right">

                                                                                {(this.state.hpId) ?
                                                                                    <ButtonStyle type="button" className={`btn ${styles.btnMargin}`} handleClick={this.handleCancel}>
                                                                                        Cancel
                                                                                    </ButtonStyle> : null}
                                                                                {(this.state.package !== 'basic' && this.state.package) && (this.props.post_data.hpId && this.props.post_data.listingStatus !== 'draft') ?
                                                                                    <ButtonStyle disabled={this.state.errors.email_address.length
                                                                                        || this.state.errors.contact_number.length || this.state.errors.website.length} className={`btn ${styles.btnMargin}`}
                                                                                        handleClick={this.savePostSettings}
                                                                                    >Save Changes</ButtonStyle> : null}
                                                                                {this.state.package === 'basic' || (this.state.selectedListing.value === 'article') || (this.state.selectedListing.value === 'clips') || (this.state.selectedListing.value === 'news') || (this.state.selectedListing.value === 'photos') || (this.state.selectedListing.value === 'album') || (this.state.selectedListing.value === 'reviews') ?
                                                                                    <span className={`${styles.back_pad}`}>
                                                                                        <ButtonStyle type="button" className="btn" handleClick={() => { this.saveAsDraft('draft') }} text={authservice.getApprovalStatus() ? "Save As Draft" : "Save As Draft"} />
                                                                                    </span> : null}

                                                                                <ButtonStyle disabled={!validateForm(errors)}
                                                                                    type="button" className="btn"
                                                                                    handleClick={this.saveAddress}>
                                                                                    {((this.state.package === 'basic') || (this.state.selectedListing.value === 'article') || (this.state.selectedListing.value === 'clips') || (this.state.selectedListing.value === 'news') || (this.state.selectedListing.value === 'photos') || (this.state.selectedListing.value === 'album') || (this.state.selectedListing.value === 'reviews')) ?
                                                                                        <span>Preview</span> : null}
                                                                                    {(this.state.package === 'advanced') || (this.state.package === 'premium') ?
                                                                                        <span>Next</span> : null}
                                                                                </ButtonStyle>
                                                                            </div>
                                                                        </div> : null
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </>}
                        </fieldset>
                        {(this.state.readOnly) ?
                            <div className="form-group row form-row">
                                <div className="col-md-10 text-right">
                                    <ButtonStyle type="button" className={`btn ${styles.btnMargin}`} handleClick={() => this.props.history.push('/admin-panel/manage-posts')}>
                                        Cancel
                                    </ButtonStyle>
                                    {((this.state.package === 'advanced') || (this.state.package === 'premium') || (this.props.post_data.orders && this.props.post_data.orders.length))
                                        ? <ButtonStyle type="button" className="btn"
                                            handleClick={this.saveAddress}>
                                            <span>Next</span>
                                        </ButtonStyle> : null}</div></div>
                            : null}
                    </div>
                </div>
                <div className={`listings row ${styles.large_rect} ${styles.large_rect_margin}`}>
                    {(this.state.readOnly) ? null : <LargeRectangle spot={"-1"} />}
                </div>
                <div className={`${styles.billBoard}`}>
                    {(this.state.readOnly) ? null : <BillBoard spot={"-1"} />}
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        allCategories: (allcategories) => dispatch({ type: 'all_categories', all_categories: allcategories }),
        fullAddress: (fulladdress) => dispatch({ type: "full_address", full_address: fulladdress }),
        sendData: (bodyData) => dispatch({ type: 'SEND_DATA', post_data: bodyData }),
        setAddress: (setAdr) => dispatch({ type: "set_address", set_address: setAdr }),
        clearPropsData: () => dispatch({ type: 'CLEAR_DATA' })
    }
}
const mapStateToProps = state => {
    return {
        post_data: state.post_data,
        all_categories: state.all_categories,
        full_address: state.full_address,
        set_address: state.set_address,
        is_login: state.is_login,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPost);