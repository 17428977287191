import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const Posts = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main className="grid_container terms_of_service">
            <div className="row">
                <section className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>EARN ADDITIONAL INCOME ONLINE BY ADDING POST</h1>
                    <ul>
                        <li>
                            <h2>TYPES OF POSTS:</h2>
                            <p>“aajubaaju.in” has 5 different segments for you to post, namely</p>
                            <p>Business Listings<br></br>
                                Event Listings<br></br>
                                Job Listings<br></br>
                                AajuBaaju My Pages<br></br>
                                AajuBaaju My Videos<br></br>
                                AajuBaaju My Reviews<br></br>
                                AajuBaaju My Photos
                            </p>
                            <p>You can post in one or more or all the segments to make an earning from the advertisements displayed in your post.</p>
                        </li>
                        <li>
                            <h2>EARN BY ADDING POSTS:</h2>
                            {/* <div>
                                <video className={FooterStyle.advt_video} height="auto" controls>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Post.mp4"} type="video/mp4" />
                                </video>
                            </div> */}
                            <p>Your approved posts will be available for the consumers to search and find in aajubaaju.in.</p>
                            <p>If a consumer visits your post, there will be advertisements displayed in your post. When an advertisement remains in focus for 30 seconds on the consumer&#39;s screen, you will get a revenue share of Rs.0.75/- per advertisements.</p>
                            <p>There is a probability of 1 to 6 advertisements completing the focus of 30 seconds to earn from Rs.075/- to Rs.4.5/- from a post.</p>
                            <p>To explain in detail:</p>
                            <ol type='none'>
                                <p>1. When a consumer searches for a grocery shop in aajubaaju.in, a list of grocery shops will be presented to the consumer in the lookup page to choose.</p>
                                <p>2. The consumer will select one of the grocery shops from the list. If the selected grocery shop information is posted by you, you will get revenue share based on the no. of advertisements completing 30 seconds focus in your post.</p>
                            </ol>
                            <p>The earning earned from the revenue share in your posts will be paid daily.</p>
                        </li>
                        <li>
                            <h2>ADVERTISEMENT DISPLAY COUNT (ADC):</h2>
                            <p>The process of determining the count of how many advertisements will be displayed in the listings posted by an AajuBaajuian is called as “Advertisement Display Count (ADC)”.</p>
                            <p>The no. of advertisement display count in the listings which have the “Eligibility of Advertisement Display” is based on the no. of premium AajuBaajuians invited by an AajuBaajuian.</p>
                            <p>0 Premium AajuBaajuians – 6 Advertisement Count <br></br>
                                1 Premium AajuBaajuians – 12 Advertisement Count<br></br>
                                2 Premium AajuBaajuians – 18 Advertisement Count<br></br>
                                and multiples of 6 Advertisement Count per AajuBaajuian
                            </p>
                            <p>To learn more about how to earn additional income online by adding post, send a whatsapp message to 8341370819</p>
                        </li>
                    </ul>
                    <div className='row pb-1'>
                         {/* style={{paddingBottom:'5px'}}> */}
                        <div className='col-md-9'></div>
                        <div className="col-md-3 add_button animated_back_button">
                            <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                        </div>
                    </div>
                </section>
                <footer className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </footer>
            </div>
        </main>
    )
}

