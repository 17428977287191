import React, { useEffect, useState } from 'react';
import { CardsListingData } from './CardsListingData';
import styles from './cover.module.css'

export const CardsListings = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }, []);

    return (
            <div className="row col-12 col-md-12 g-3 m-1">
                {props.listings.map(listing => (
                    <div key={listing.hpId} className={`col-12 col-md-6 mb-3 ${styles.card_transition} ${loaded ? `${styles.show}` : ''}`}>
                        <CardsListingData
                            {...props}
                            key={listing.hpId}
                            listing={listing}
                            isViewed={props.viewedContentIds.includes(listing.hpId)}
                        />
                    </div>
                ))}
            </div>
    )
}
